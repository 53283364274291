import React, { useState } from 'react';
import './instruction.scss';
import der from './der.png';
import { useTranslation } from 'react-i18next';

import icon1 from './Images/icon1.png';
import icon2 from './Images/icon2.png';
import icon3 from './Images/icon3.png';
import icon4 from './Images/icon4.png';
import image1 from './Images/t1.png';
import image2 from './Images/t2.png';
import image3 from './Images/t3.png';
import image4 from './Images/t4.png';

import vector from './Images/Vector.png';
import listing from "./Images/listing.png"
import Countdown from './Countdown';

function Instruction(props) {
    const { setShow } = props;
    const { t } = useTranslation();

    const info = [
        {
            id: 1,
            image: image1,
            title: t('instructions.0.title'),
            icon: icon1,
            subtext: t('instructions.0.subtext')
        },
        {
            id: 2,
            image: image3,
            title: t('instructions.1.title'),
            icon: icon2,
            subtext: t('instructions.1.subtext')
        },
        {
            id: 3,
            image: image2,
            title: t('instructions.2.title'),
            icon: icon3,
            subtext: t('instructions.2.subtext')
        },
        {
            id: 4,
            image: image4,
            title: t('instructions.3.title'),
            icon: icon4,
            subtext: t('instructions.3.subtext')
        },
        {
            id: 5,
            image: "",
            title: "",
            icon: "",
            subtext: ""
        }
    ];
    const [currentPage, setCurrentPage] = useState(0);

    const handleNext = () => {
        if (currentPage === info.length - 1) {
            setShow(false);
        } else {
            setCurrentPage((currentPage + 1) % info.length);
        }
    };

    return (
        <div className='outer_container_instruction'>
            <div className='container_glow_instruction'>


                {currentPage == 4 ?
                    <>
                        <div className='flex_instruction'>
                            <img src='https://nikicoin.store/media/newcoin_NIKI/nikicoin00.png'></img>
                            <h1 style={{ textAlign: "center", color: "white" }}>LISTING TIME</h1>
                        </div>
                        <Countdown />

                        <img className='img_listing' src={listing}></img>
                        <div className='start_btn' onClick={() => setShow(false)}>START</div>
                    </>
                    :
                    <>

                        <div className='flex_instruction'>
                            <img src={info[currentPage].icon} alt='Icon' />
                            <div className='flex'>
                                <h1 style={{ color: "white" }}>{info[currentPage].title}</h1>
                                <p>{info[currentPage].subtext}</p>
                            </div>

                        </div>


                        <div className='next_container'>
                            <button className='button_next' onClick={handleNext}>
                                <img src={vector} alt='Next' />
                            </button>
                            {currentPage + 1}/4
                        </div>
                        <img
                            className='image_instruction'
                            src={info[currentPage].image}
                            alt={info[currentPage].title}
                        />
                        <div className='shadow pink'></div>
                        <div className='shadow blue'></div>
                        <div className='shadow black'></div>
                    </>
                }


            </div>
        </div>
    );
}

export default Instruction;
