import { LockedIcon } from "./Icons";
import "./LockedTask.scss";
import { useState, useEffect } from "react";

export const LockedTask = ({ lvl, className }) => {
  const [stars, setStars] = useState(0);

  useEffect(() => {
    if (lvl <= 1) {
      setStars(1);
    } else if (lvl <= 4) {
      setStars(2);
    } else if (lvl <= 7) {
      setStars(3);
    } else {
      setStars(0);
    }
  }, [lvl]);

  return (
    <div className={`locked-wrapper ${className}`}>
      <ul className="stars-list">
        {Array.from({ length: stars }).map((_, i) => (
          <li key={i} className="star-locked-icon">
            ⭐️
          </li>
        ))}
      </ul>
      <LockedIcon className="locked-icon" />
      <div className="require-level">LVL {lvl}+</div>
    </div>
  );
};
