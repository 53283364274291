import React, { useState } from 'react';
import Pet from './Pet';
import "./petChoosing.scss";
import Navigate from '../../NavigateBtn/Navigate';
import { useAppData } from '../../../AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PetChoosing = () => {
    const { t } = useTranslation();
    const { allPets } = useAppData();
    const [selectedPet, setSelectedPet] = useState(null);

    const handleSelectPet = (pet) => {
        setSelectedPet(pet);
    };

    const navigate = useNavigate();

    function handleNavigate(id) {
        navigate("/PetNaming/" + id);
    }

    return (
        <div className="pets">
            <Navigate position="left" navigateTo="/" />
            <div className='choose_header'>{t('petChoosing.chooseYourPet')}</div>

            <div className="pets-grid">
                {allPets && allPets.map(pet => (
                    <Pet key={pet.id} pet={pet} onSelectPet={() => handleSelectPet(pet)} isSelected={selectedPet && pet.id === selectedPet.id} />
                ))}
            </div>
            <button className="buy-pet-button" onClick={selectedPet ? () => handleNavigate(selectedPet.id) : undefined}>
                {selectedPet ? t('petChoosing.buyPet', { price: selectedPet.price_coins.toLocaleString('en-US') }) : t('petChoosing.selectAPet')}
            </button>
        </div>
    );
};

export default PetChoosing;
