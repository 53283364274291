import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tasks from "./Tasks.png";
import Friends from "./Friends.png";
import Games from "./Games.png";
import Shop from "./Shop.png";
import Exchange from "./Exchange.png";
import "./footer.css";
import ComingSoon from '../ComingSoon/ComingSoon';
import map from "./map.png";
import Earn from "./Earn.png";
import Map from '../Map/Map';
import { useTranslation } from 'react-i18next';
import Listing from '../Listing/Listing';
import { useAppData } from '../../AppContext';
export default function Footer() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const location = useLocation();
    const isMainPet = location.pathname === '/MainPet';
    const navigate = useNavigate();
    const { setShowNavigate } = useAppData()
    return (
        <>
            {showMap ? <Map onClick={() => {
                setShowMap(false);
                setShowNavigate(true);
            }} /> : null}

            {show ? <Listing setShow={() => setShow(false)} /> : null}

            <div className="footer">
                <div className="menu">
                    <div className="menu-button" onClick={() => {
                        if (isMainPet) {
                            setShowMap(true);
                            setShowNavigate(false)
                        } else {
                            navigate('/earn/tasks');
                        }
                    }}>
                        <img src={isMainPet ? map : Earn} alt={isMainPet ? t('footer.map') : t('footer.earn')} />
                        <span className="button-text">{isMainPet ? t('footer.map') : t('footer.earn')}</span>
                    </div>
                    <div className="divider"></div>
                    <div className="menu-button" onClick={() => navigate('/frens')}>
                        <img src={Friends} alt="Friends" />
                        <span className="button-text">{t('footer.friends')}</span>
                    </div>
                    <div className="divider"></div>
                    <div className="menu-button " onClick={() => navigate('/games')}>
                        <img src={Games} className='glow_games' alt="Games" />
                        <span className="button-text">{t('footer.games')}</span>
                    </div>
                    <div className="divider"></div>
                    <div className="menu-button" onClick={() => navigate('/shop/pets')}>
                        <img src={Shop} alt="Shop" />
                        <span className="button-text">{t('footer.shop')}</span>
                    </div>
                    <div className="divider"></div>
                    <div className="menu-button" onClick={() => setShow(true)}>
                        <img src={Exchange} alt="Exchange" style={{ width: "28px", height: "31px" }} />
                        <span className="button-text">{t('footer.exchange')}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
