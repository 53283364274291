import React, { useEffect, useRef, useState } from 'react';
import "./MainPet.scss";
import { useAppData } from '../../../AppContext';
import Navigate from "../../NavigateBtn/Navigate";
import Footer from '../../Footer/Footer';
import dollar_coin from "./dollar_coin.png";
import { feedPet, getPlayerPet } from '../../../http';
import Header from './Header';
import ToFood from './Modals/ToFood';
import banana from './🍌.png';
import carrotImage from './🥕.png';
import fish from './🐟.png';
import meat from './🍖.png';
import sleepIcon from './😴.png';
import starIcon from './⭐.png';
import thanks from "./thanks.png";
import { useTranslation } from 'react-i18next';
import NotEnoughMoney from './NotEnoughMoney/NotEnoughMoney';
import map from "./map.png"
import gem from "./gem.png"
import Instruction from '../../Instruction/Instruction';
const formatTime = (time) => {
    const hours = String(time.hours).padStart(2, '0');
    const minutes = String(time.minutes).padStart(2, '0');
    const seconds = String(time.seconds).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
};

const Countdown = () => {
    const { closestQuizTime } = useAppData();

    const calculateTimeLeft = () => {
        const difference = +new Date(closestQuizTime) - +new Date();
        let timeLeft = { hours: 0, minutes: 0, seconds: 0 };

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [closestQuizTime]);

    return (
        <div className="gradient_text_mainpet flex_timer">
            {formatTime(timeLeft)}
            <img className='map_img' src={map}></img>
        </div>
    );
};

function MainPet() {
    const { t } = useTranslation();
    const { coins, Pet, handleEditCoins, closestQuizTime, showNavigate, diamonds } = useAppData();
    const [pet, setPetData] = useState(Pet);
    const [totalSpent, setTotalSpent] = useState(pet.total_spent);
    const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
    const [fedTimes, setFedTimes] = useState(Pet.fed_times);
    const [showFood, setShowFood] = useState(true);
    const [shopModal, setShopModal] = useState(false);
    const [showModalPet, setShowModalPet] = useState(false);
    const [carrots, setCarrots] = useState([]);
    const [error, setError] = useState(false)
    const carrotCount = 20;
    const [show, setShow] = useState(false);


    const [nextFeedTime, setNextFeedTime] = useState(null);
    const [countdown, setCountdown] = useState(null);

    const cooldown = 10800;

    useEffect(() => {
        async function fetchData() {
            const data = await getPlayerPet();
            setPetData(data);
        }

        fetchData();
    }, []);

    useEffect(() => {
        const lastFedTimestamp = pet.last_time_fed;
        const nextFeedTimestamp = lastFedTimestamp + cooldown;
        const currentTime = Math.floor(Date.now() / 1000);
        if (currentTime < nextFeedTimestamp) {
            setNextFeedTime(nextFeedTimestamp);
        }
    }, [pet.last_time_fed]);

    useEffect(() => {
        let interval = null;
        if (nextFeedTime) {
            interval = setInterval(() => {
                const currentTime = Math.floor(Date.now() / 1000);
                const secondsLeft = nextFeedTime - currentTime;
                setCountdown(secondsLeft > 0 ? formatTime(secondsLeft) : "0:00:00");
                if (secondsLeft <= 0) {
                    clearInterval(interval);
                    setNextFeedTime(null);
                }
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [nextFeedTime]);

    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    useEffect(() => {
        if (window.Telegram.WebApp.BackButton.isVisible) {
            window.Telegram.WebApp.BackButton.hide();
        }
    }, []);

    const coinBar = useRef(null);

    const defaultFloatingFood = [banana, carrotImage, fish, meat];
    const sleepFloating = [sleepIcon, starIcon];

    const levels = [
        { level: 1, required: 100000, nextLevel: "300K" },
        { level: 2, required: 300000, nextLevel: "700K" },
        { level: 3, required: 700000, nextLevel: "1.5M" },
        { level: 4, required: 1500000, nextLevel: "3.1M" },
        { level: 5, required: 3100000, nextLevel: "6.3M" },
        { level: 6, required: 6300000, nextLevel: "12.7M" },
        { level: 7, required: 12700000, nextLevel: "25.5M" },
        { level: 8, required: 25500000, nextLevel: "51.1M" },
        { level: 9, required: 51100000, nextLevel: "102.3M" },
        { level: 10, required: 102300000, nextLevel: "204.7M" },
        { level: 11, required: 204700000, nextLevel: "409.5M" },
        { level: 12, required: 409500000, nextLevel: "819.1M" },
        { level: 13, required: 819100000, nextLevel: "1.64B" },
        { level: 14, required: 1638300000, nextLevel: "3.28B" },
        { level: 15, required: 3276700000, nextLevel: "6.55B" },
        { level: 16, required: 6553500000, nextLevel: "13.11B" },
        { level: 17, required: 13107100000, nextLevel: "26.21B" },
        { level: 18, required: 26214300000, nextLevel: "52.43B" },
        { level: 19, required: 52428700000, nextLevel: "104.86B" },
        { level: 20, required: 104857500000, nextLevel: "209.72B" },
        { level: 21, required: 209715100000, nextLevel: "419.43B" },
        { level: 22, required: 419430300000, nextLevel: "838.86B" },
        { level: 23, required: 838860700000, nextLevel: "1.68T" },
        { level: 24, required: 1677721500000, nextLevel: "3.36T" },
        { level: 25, required: 3355443100000, nextLevel: null }
    ];

    const feedCosts = {
        1: 10000,
        2: 20000,
        3: 40000,
        4: 80000,
        5: 160000,
        6: 320000,
        7: 640000,
        8: 1280000,
        9: 2560000,
        10: 5120000,
        11: 10240000,
        12: 20480000,
        13: 40960000,
        14: 81920000,
        15: 163840000,
        16: 327680000,
        17: 655360000,
        18: 1310720000,
        19: 2621440000,
        20: 5242880000,
        21: 10485760000,
        22: 20971520000,
        23: 41943040000,
        24: 83886080000,
        25: 167772160000
    }

    const getPetLogo = () => {
        if (fedTimes === 0) {
            return pet.logo_hungry;
        } else if (fedTimes >= 3) {
            return pet.logo_resting;
        } else {
            return pet.logo_without_background;
        }
    };

    const handleFeed = async () => {
        if (fedTimes < 3) {
            if (coins > feedCosts[pet.level]) {
                await feedPet();
                handleEditCoins(-feedCosts[pet.level]);
                setFedTimes(prevTimes => prevTimes + 1);
                setTotalSpent(prevSpent => prevSpent + feedCosts[pet.level]);
                setShowFood(true);
                setTimeout(() => {
                    setShowFood(false);
                }, 2000);
            }
            else {
                setError(true)
            }
        }
    };

    useEffect(() => {
        const levelIndex = levels.findIndex(level => totalSpent < level.required);
        setCurrentLevelIndex(levelIndex === -1 ? levels.length - 1 : levelIndex);
    }, [totalSpent]);

    useEffect(() => {
        if (coinBar.current && currentLevelIndex < levels.length) {
            const currentLevelRequirement = levels[currentLevelIndex].required;
            const progress = totalSpent / currentLevelRequirement;
            coinBar.current.style.width = `${Math.min(100, progress * 100)}%`;
        }
    }, [totalSpent, currentLevelIndex]);

    useEffect(() => {
        setFedTimes(pet.fed_times);
        setTotalSpent(pet.total_spent);
    }, [pet.fed_times, pet.total_spent]);

    const getIcons = () => {
        if (pet.selected_food === "") {
            return defaultFloatingFood;
        } else if (fedTimes >= 3) {
            return sleepFloating;
        } else {
            return [pet.selected_food_logo];
        }
    };

    const updateCarrotPosition = () => {
        setCarrots(prevCarrots =>
            prevCarrots.map(carrot => ({
                ...carrot,
                y: (carrot.y + carrot.speed) % window.innerHeight
            }))
        );
    };

    useEffect(() => {
        const initializeCarrots = () => {
            const newCarrots = Array.from({ length: carrotCount }, () => ({
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight - 300,
                speed: Math.random() * 2 + 1
            }));
            setCarrots(newCarrots);
        };

        initializeCarrots();

        const interval = setInterval(() => {
            updateCarrotPosition();
        }, 30);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const floatingFood = getIcons();
        const newCarrots = Array.from({ length: carrotCount }, (_, index) => ({
            img: floatingFood[index % floatingFood.length],
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight - 300,
            speed: Math.random() * 2 + 1
        }));
        setCarrots(newCarrots);
    }, [pet.selected_food, fedTimes]);

    function formatNumber(num) {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        }
        else return num;
    }

    function formatNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return (
        <div className='mainpet_container'>
            {shopModal && <ToFood onClick={() => setShopModal(false)} />}
            {error ? <NotEnoughMoney onClick={() => setError(false)} /> : ""}
            {showNavigate && <Navigate position="left" navigateTo="/" />}

            {showModalPet && (
                <div className='outer_container_pet_modal'>
                    <div className='container_glow_mainpet_modal'>
                        <span className="close" onClick={() => setShowModalPet(false)}>&times;</span>
                        <img src={thanks} className='thanks_mainpet_img' alt="Thanks" />
                        <h1>{t('mainPet.thanks')}</h1>
                        <div>
                            {t('mainPet.notHungry')}
                            <span className="gradient_text_mainpet"> {countdown} </span>
                        </div>
                    </div>
                </div>
            )}

            <Header pet={pet} />
            {show && <Instruction setShow={setShow} />}

            <div className='outer-container_pet'>
                <div className='container_glow_mainpet'>
                    <div className='column'>
                        <div className="stats">
                            <img src={dollar_coin} className="stats-coin" alt="Coin-icon" />
                            <div id="balance" className="coin-count">{coins.toLocaleString()}</div>
                        </div>
                        <div className="coin-wrapper">
                            <div className="coin-bar">
                                <div className="coin" ref={coinBar}></div>
                            </div>
                            <div className="coin-counter">
                                <span className='green_mainpet'>{levels[pet.level]?.nextLevel} {t('mainPet.levelUp')} {pet.level + 1}</span>
                            </div>
                        </div>
                        <div className="stats" onClick={() => setShow(true)}>
                            <img src={gem} className="stats_gem" alt="Coin icon" />
                            <div id="balance" className="gem_count">{diamonds}</div>
                        </div>
                    </div>

                    <div className='pet_container'>
                        <img className='pet_img_main' src={getPetLogo()} alt="Your Pet" />
                        <div className='pet-glow'></div>
                        <div className='food'>
                            {carrots.map((carrot, index) => (
                                <div key={index} style={{ position: "absolute", left: carrot.x, top: carrot.y }}>
                                    <img src={carrot.img} className="food-fly-icon" alt="Floating Food" />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div style={{ width: "100%" }}>
                        {pet.selected_food === "" ? (
                            <div className={`feed_btn ${fedTimes >= 3 ? 'disabled' : ''}`} onClick={() => setShopModal(true)}>
                                <div className='flex_pet'>
                                    {formatNumber(feedCosts[pet.level])}
                                    <img src={dollar_coin} className="coin_feed" alt="Coin-icon" />
                                </div>
                                <div className='d-flex'>
                                    <div>{t('mainPet.feed')}</div>
                                    {fedTimes < 3 && <img src={pet.selected_food_logo} className="food-feed" alt="Food" />}
                                </div>
                                <div>{fedTimes}/3</div>
                            </div>
                        ) : (
                            <div className='disabled_fed_container'>
                                <div className={`feed_btn ${fedTimes >= 3 ? 'disabled_btn' : ''}`} onClick={fedTimes >= 3 ? () => setShowModalPet(true) : handleFeed}>
                                    <div className='flex_pet'>
                                        {formatNumber(feedCosts[pet.level])}
                                        <img src={dollar_coin} className="coin_feed" alt="Coin-icon" />
                                    </div>
                                    <div className='d-flex'>
                                        <div>{t('mainPet.feed')}</div>
                                        {fedTimes < 3 && <img src={pet.selected_food_logo} className="food-feed" alt="Food" />}
                                    </div>
                                    <div>{fedTimes}/3</div>
                                </div>
                                <div className='flex_pet'>
                                    {fedTimes >= 3 && closestQuizTime && new Date(closestQuizTime) > new Date() ? <Countdown /> : <></>}
                                    {fedTimes >= 3 && <div className="gradient_text_mainpet"> {countdown} </div>}
                                </div>
                            </div>
                        )}
                        {closestQuizTime && new Date(closestQuizTime) > new Date() && fedTimes < 3 ? <Countdown /> : <></>}

                    </div>

                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default MainPet;