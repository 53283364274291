import React, { useState, useRef, useCallback, useEffect, Fragment } from 'react';
import "./map.scss";
import { OpenedTask } from "./OpenedTask/OpenedTask";
import { useTranslation } from "react-i18next";
import { getQuizDetail, getQuizMapsAndQuizzes, completeQuiz } from "../../http";
import { useAppData } from "../../AppContext";
import { NavLink } from "react-router-dom";
import { LockedTask } from './LockedTask/LockedTask';

const QuizMapListPage = ({ maps, setRouting }) => {
  const handleMapClick = (mapName) => {
    const selectedMap = maps.find((map) => map.name === mapName);
    if (selectedMap) {
      setRouting(["quiz_map", selectedMap.id]);
    }
  };

  return (
    <div>
      <img src="/images/map.png" alt="map" className="map" />
      <div className="island1" onClick={() => handleMapClick("ice")}>
        <img src="/images/finger.png" className="finger_one" alt="island1" />
      </div>
      <div className="island2" onClick={() => handleMapClick("city")}>
        <img src="/images/finger.png" className="finger_two" alt="island2" />
      </div>
    </div>
  );
};

const TimeoutCounter = ({ time_finished, timeout_minutes, onTimeout, className, required_color }) => {
  const { Pet, setClosestQuizTimes } = useAppData();

  const [timeLeft, setTimeLeft] = useState(
    timeout_minutes * 60 - (new Date().getTime() - new Date(time_finished).getTime()) / 1000
  );
  useEffect(() => {
    if (time_finished) {
      const interval = setInterval(() => {
        const newTimeLeft = timeout_minutes * 60 - (new Date().getTime() - new Date(time_finished).getTime()) / 1000;
        setTimeLeft(newTimeLeft);

        if (newTimeLeft <= 0 && Pet.color === required_color) {
          clearInterval(interval);
          onTimeout();
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  if (timeLeft <= 0) {
    return null;
  }

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = Math.floor(timeLeft % 60);

  return (
    <div className={className}>
      <p>
        {Math.max(hours, 0)}:{Math.max(minutes, 0).toString().padStart(2, "0")}:
        {Math.max(seconds, 0).toString().padStart(2, "0")}
      </p>
    </div>
  );
};

const QuizMapPage = ({ quizMapId, maps, setRouting, onTimeout, petColor, Pet, setTimer }) => {
  const map = maps.find((map) => map.id === quizMapId);
  const quizzes = map ? map.quizzes : [];
  const handleQuizClick = (quizId) => {
    setRouting(["quiz", quizId]);
  };

  const handleBackClick = () => {
    setRouting(["quiz_maps"]);
  };

  return (
    <Fragment>
      <div className="back-island" onClick={handleBackClick}>
        <img className="back-island" alt="back-img" src="/images/back.png" />
      </div>
      <img src={`/images/${map.name}.png`} alt="map" className="map" />
      {quizzes.map((quiz, idx) => (
        <div
          key={idx}
          id={`map-${idx + 1}`}
          className={`task-${idx + 1}-button task-${idx + 1}-button_timer`}
        >
          {!quiz.is_available ? (
            <>
              <div className={`task-${idx + 1}-button task-${idx + 1}-button_timer`}>
                {quizMapId === 2 && petColor === "gray" ? (
                  <img
                    onClick={() => setRouting(["nopinkmap"])}
                    className="nopinkmap"
                    src="/images/nopinkmap.png"
                    alt="no-pink-icon"
                  />
                ) : (
                  quiz.time_finished == null ? <LockedTask lvl={quiz.required_pet_level} /> :
                    <svg onClick={() => setRouting(["disabled", quiz.time_finished, quiz.timeout_minutes, quiz.required_color])}
                      width="113"
                      height="113"
                      viewBox="0 0 113 113"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="113" height="113" rx="15" fill="#A6A6A6" fillOpacity="0.35" />
                      <path
                        opacity="0.85"
                        d="M78.6428 37.5966C79.5355 38.4439 80.7076 38.7616 81.8235 38.7087H104.53C106.818 38.7087 108.714 36.9081 108.714 34.7369C108.714 32.5657 106.818 30.7652 104.53 30.7652H91.7538L107.487 15.7784C109.105 14.2427 109.105 11.7008 107.487 10.165C106.65 9.3707 105.59 9 104.53 9C103.47 9 102.41 9.3707 101.573 10.165L85.8405 25.1517V12.9717C85.8405 10.8005 83.9436 9 81.6561 9C79.3687 9 77.4717 10.8005 77.4717 12.9717V34.5251C77.3602 35.6901 77.7501 36.8022 78.6428 37.5966Z"
                        fill="white"
                      />
                      <path d="M44.7042 58.0273V62.4845C41.8623 61.9629 40.2383 61.0146 40.2383 60.2559C40.2383 59.4973 41.8623 58.5489 44.7042 58.0273Z" fill="white" />
                      <path
                        d="M45.4737 26.8281C23.726 26.8281 6 44.1636 6 65.4324C6 86.7497 23.726 104.085 45.4737 104.085C67.2215 104.085 84.8977 86.7497 84.8977 65.4324C84.8977 44.1636 67.2215 26.8281 45.4737 26.8281ZM48.2046 78.689V78.9318C48.2046 80.4371 46.9633 81.6511 45.4737 81.6511C43.9345 81.6511 42.6932 80.4371 42.6932 78.9318V78.689C40.0617 78.2034 37.728 77.038 36.1888 75.3384C35.3943 74.3672 35.295 73.0076 36.0398 71.9878C36.7349 70.9681 38.0755 70.5796 39.2672 71.0167L42.6932 72.3278V67.8603C37.8769 66.9863 34.4012 63.8785 34.4012 60.0424C34.4012 56.2062 37.8769 53.0984 42.6932 52.2244V51.933C42.6932 50.4277 43.9345 49.2137 45.4737 49.2137C46.9633 49.2137 48.2046 50.4277 48.2046 51.933V52.1758C50.8858 52.6614 53.2195 53.8754 54.7091 55.5749C55.5532 56.4976 55.6028 57.8572 54.9077 58.8769C54.3615 59.6053 53.5174 60.0424 52.6237 60.0424C52.2761 60.0424 51.9782 59.9938 51.6306 59.8481L48.2046 58.5856V63.0044C53.0209 63.8785 56.4966 66.9863 56.4966 70.871C56.4966 74.7071 53.0209 77.8149 48.2046 78.689Z"
                        fill="white"
                        fillOpacity="0.85"
                      />
                    </svg>
                )}
                <TimeoutCounter
                  time_finished={quiz.time_finished}
                  timeout_minutes={quiz.timeout_minutes}
                  onTimeout={onTimeout}
                  className={`task-${idx + 1}-city-timer`}
                  required_color={quiz.required_color}
                />
              </div>
            </>
          ) : (
            <div
              onClick={() => handleQuizClick(quiz.id)}
              style={{ width: "100px", height: "100px" }}
            >
              <OpenedTask stars={idx + 1} className={`task-${idx + 1}`} />
            </div>
          )}
        </div>
      ))}
    </Fragment>
  );
};


const QuizChoices = ({ choices, setSelectedAnswerId, isAnswerCorrect, isAnswered }) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [tabColor, setTabColor] = useState({});

  const handleTabClick = (tabId) => {
    if (selectedTab === null) {
      React.startTransition(() => {
        setSelectedTab(tabId);
        setSelectedAnswerId(tabId);
        setTabColor((prevColors) => ({ ...prevColors, [tabId]: "yellow" }));
      });
    }
  };

  useEffect(() => {
    if (selectedTab !== null) {
      if (isAnswered) {
        setTabColor((prevColors) => ({
          ...prevColors,
          [selectedTab]: isAnswerCorrect ? "greenyellow" : "red",
        }));
      }
    }
  }, [isAnswered, isAnswerCorrect, selectedTab]);

  return (
    <ul className="tab-container">
      {choices.map((choice) => (
        <li
          key={choice.id}
          id={choice.id}
          style={{ backgroundColor: tabColor[choice.id] }}
          className={`tab ${selectedTab === choice.id ? "active" : ""}`}
          onClick={() => handleTabClick(choice.id)}
        >
          {choice.text}
        </li>
      ))}
    </ul>
  );
};

const QuizDetailPage = ({ Pet, quizId, setRouting, handleRerender }) => {
  const { setClosestQuizTimes, closestQuizTime } = useAppData()
  const [quizDetail, setQuizDetail] = useState(null);
  const [selectedAnswerId, setSelectedAnswerId] = useState(null);
  const { handleEditDiamonds, handleEditCoins } = useAppData();
  const { t, i18n } = useTranslation();
  const [isAnswered, setIsAnswered] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [fanfars, setFanfars] = useState([]);
  const [XMarks, setXMarks] = useState([]);
  const fanfarsCount = 20;
  const XMarkCount = 20;

  const initializeFanfars = useCallback(() => {
    const newFanfars = Array.from({ length: fanfarsCount }, () => ({
      img: "/images/fanfar.png",
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight - 300,
      speed: Math.random() * 2 + 1,
    }));
    setFanfars(newFanfars);
  }, [fanfarsCount]);

  const updateFanfarPosition = useCallback(() => {
    setFanfars((prevFanfars) =>
      prevFanfars.map((fanfar) => ({
        ...fanfar,
        y: (fanfar.y + fanfar.speed) % window.innerHeight,
      }))
    );
  }, []);

  const initializeXMarks = useCallback(() => {
    const newXMarks = Array.from({ length: XMarkCount }, () => ({
      img: "/images/wrong-answer.png",
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight - 300,
      speed: Math.random() * 2 + 1,
    }));
    setXMarks(newXMarks);
  }, [XMarkCount]);

  const updateXMarkPosition = useCallback(() => {
    setXMarks((prevXMarks) =>
      prevXMarks.map((xMark) => ({
        ...xMark,
        y: (xMark.y + xMark.speed) % window.innerHeight,
      }))
    );
  }, []);

  useEffect(() => {
    initializeFanfars();
    const fanfarInterval = setInterval(updateFanfarPosition, 30);
    return () => clearInterval(fanfarInterval);
  }, [initializeFanfars, updateFanfarPosition]);

  useEffect(() => {
    initializeXMarks();
    const xMarkInterval = setInterval(updateXMarkPosition, 30);
    return () => clearInterval(xMarkInterval);
  }, [initializeXMarks, updateXMarkPosition]);

  const fetchQuizDetail = useCallback(async () => {

    const data = await getQuizDetail(quizId, i18n.language);
    if (data) {
      setQuizDetail(data);
    }
  }, [quizId]);

  useEffect(() => {
    fetchQuizDetail();
  }, [fetchQuizDetail]);

  const fetchCompleteQuiz = useCallback(async () => {
    const data = await completeQuiz(quizDetail.question_id, Pet.pet_id, selectedAnswerId, quizDetail.quiz_id);
    if (data) {
      React.startTransition(() => {
        setIsAnswerCorrect(data.status);
        if (data.status == true) {
          handleEditDiamonds(quizDetail.diamonds_reward)
          handleEditCoins(quizDetail.coins_reward)
        }
        const now = new Date();
        const newQuizTime = new Date(now.getTime() + (quizDetail.quiz_timeout * 60 * 1000));
        if (closestQuizTime !== null) {
          if (closestQuizTime > newQuizTime) {
            setClosestQuizTimes(newQuizTime);
          }
        }
        else {
          setClosestQuizTimes(newQuizTime);

        }
        setIsAnswered(true);
      });
    }
  }, [Pet.pet_id, quizDetail, selectedAnswerId]);

  useEffect(() => {
    if (selectedAnswerId) {
      fetchCompleteQuiz();
    }
  }, [selectedAnswerId, fetchCompleteQuiz]);

  const handleLeave = useCallback(() => {
    React.startTransition(() => {
      handleRerender();
      setRouting(["quiz_maps"]);
    });
  }, [handleRerender, setRouting]);

  if (!quizDetail) {
    return <div>Loading...</div>;
  }

  const handleClaim = (coins, diamonds) => {
    handleEditCoins(coins);
    handleEditDiamonds(diamonds);
    handleLeave();
  };

  return (
    <Fragment>
      <img className={`boss boss_${quizDetail.boss_type}`} alt={quizDetail.boss_type} src={`/images/${quizDetail.boss_type}.png`} />
      <span className="close-icon" onClick={handleLeave}>
        <img src="/images/close.png" alt="close-img" />
      </span>
      <div className="task-bg">
        <div className="main">
          <h2 className="title">{quizDetail.boss_type}</h2>
          <div className="question">{quizDetail.question}</div>
          <QuizChoices
            isAnswered={isAnswered}
            isAnswerCorrect={isAnswerCorrect}
            choices={quizDetail.choices}
            setSelectedAnswerId={setSelectedAnswerId}
          />
          <div className="reward">{t("quiz.rewards")}:</div>
          <img className="treasure" src="/images/TreasureChest.png" alt="treasure" />
          <img className="gems" src="/images/Gems.png" alt="gems" />
          <div className="rewards-data">
            <div className="coins-data">
              <img className="reward-coin" alt="coin-img" src="/images/coin.png" />
              {quizDetail.coins_reward}
            </div>
            <div className="gems-data">
              <img className="gem" alt="gem-img" src="/images/gem.png" />
              {quizDetail.diamonds_reward}
            </div>
          </div>
          {isAnswered && isAnswerCorrect && (
            <Fragment>
              <button onClick={() => handleClaim(quizDetail.coins_reward, quizDetail.diamonds_reward)} className="claim-button">
                {t("quiz.claim")}
              </button>
              {fanfars.map((fanfar, index) => (
                <div
                  key={index}
                  id={`fanfar-${index + 1}`}
                  style={{
                    position: "absolute",
                    left: fanfar.x,
                    top: fanfar.y,
                  }}
                >
                  <img src={fanfar.img} className="food-fly-icon" alt="Floating Food" />
                </div>
              ))}
            </Fragment>
          )}
          {isAnswered && !isAnswerCorrect && (
            <Fragment>
              <button onClick={handleLeave} className="leave-button">
                {t("quiz.leave")}
              </button>
              {XMarks.map((xMark, index) => (
                <div
                  key={index}
                  id={`xmark-${index + 1}`}
                  style={{
                    position: "absolute",
                    left: xMark.x,
                    top: xMark.y,
                  }}
                >
                  <img src={xMark.img} className="food-fly-icon" alt="Floating Food" />
                </div>
              ))}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const App = ({ onClick }) => {
  const [routing, setRouting] = useState(["quiz_maps"]);
  const { Pet } = useAppData();
  const [petColor, setPetColor] = useState(Pet.color);
  const [timer, setTimer] = useState(null);
  const [maps, setMaps] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const handleRerender = () => {
    fetchQuizMapsAndQuizzes()
  };

  const handleLeave = useCallback(() => {
    React.startTransition(() => {
      handleRerender();
      setRouting(["quiz_maps"]);
    });
  }, [handleRerender, setRouting]);

  const fetchQuizMapsAndQuizzes = useCallback(async () => {
    if (!Pet.pet_id) return;
    setLoading(true);
    const data = await getQuizMapsAndQuizzes(Pet.pet_id);
    if (data) {
      setMaps(data.maps);
    }
    setLoading(false);
  }, [Pet.pet_id]);

  useEffect(() => {
    fetchQuizMapsAndQuizzes();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (maps?.error) {
    return <div>{maps.error}</div>;
  }

  return (
    <Fragment>
      <div className="outer_container_map">
        {(routing[0] === "quiz_maps" || routing[0] === "quiz_map") && (
          <span className="close close_map" onClick={onClick}>
            <img src="/images/close.png" alt="close" />
          </span>
        )}
        <div className="container_glow_map">
          {routing[0] === "quiz_maps" && <QuizMapListPage maps={maps} setRouting={setRouting} />}
          {routing[0] === "quiz_map" && (
            <QuizMapPage
              petColor={petColor}
              quizMapId={routing[1]}
              maps={maps}
              setRouting={setRouting}
              onTimeout={handleRerender}
              Pet={Pet}
              setTimer={setTimer}
            />
          )}
          {routing[0] === "quiz" && (
            <QuizDetailPage
              Pet={Pet}
              quizId={routing[1]}
              setRouting={setRouting}
              handleRerender={handleRerender}
            />
          )}
          {routing[0] === "nopinkmap" && (
            <div className="nopink-wrapper">
              <h2 className="nopink-title">{t("nopink.title")}</h2>
              <img src="/images/nopink-img.png" alt="nopink" />
              <button onClick={handleLeave} className="leave-button leave-button_nopink">
                OK
              </button>
            </div>
          )}
          {routing[0] === "disabled" && (
            <div className="nopink-wrapper">
              <span className="close-icon" onClick={() => setRouting(["quiz_maps"])}>
                <img src="/images/close.png" alt="close-img" />
              </span>
              <h2 className="nopink-title">{t("disabled.title")}</h2>
              <img className='disabled_img' src="/images/disabled.png" alt="nopink" />
              <TimeoutCounter
                time_finished={routing[1]}
                timeout_minutes={routing[2]}
                onTimeout={handleRerender}
                className="timer_disabled"
                required_color={routing[3]}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default App;

