import React from 'react'
import "./SiteDisabled.scss"
import img from "./photo.png"
function SiteDisabled() {
    function openTelegramLink() {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink('https://t.me/nikicompany');
        }
    }
    function openLink() {
        if (window.Telegram.WebApp) {
            window.open('https://t.me/playdeckbot?start=gi0uL', "_blank")
        }
    }

    return (
        <div className='outer_container_disabled'>

            <div className='container_glow_disabled'>

                <div className="gradient-text">TECHNICAL WORK</div>

                <div className='button_sitesibabled_container'>
                    <div className='button_sitesibabled' onClick={openTelegramLink}>Telegram</div>
                    <div className='button_sitesibabled' onClick={openLink}>NikiBoom</div>

                </div>

                <img src={img}></img>
            </div>
        </div>
    )
}

export default SiteDisabled
