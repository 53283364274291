import React from 'react';
import "./thanks.scss";
import img from "./img.png";
import { useAppData } from '../../AppContext';
import { useTranslation } from 'react-i18next';

function ThanksNiki({ coins }) {
    const { t } = useTranslation();
    const { handleEditCoins, setpassiveIncome } = useAppData();

    const handleClick = () => {
        setpassiveIncome(0);
        console.log(coins)
        handleEditCoins(coins);

        // // Trigger flying coins animation
        // for (let i = 0; i < 20; i++) {
        //     createFlyingCoin();
        // }

        // let container = document.querySelector("#niki")
        // container.classList.add('gold-background');
        // setTimeout(() => {
        //     container.classList.remove('gold-background');
        // }, 1000);
    };

    // const createFlyingCoin = () => {
    //     const coin = document.createElement('div');
    //     coin.className = 'flying-coin';

    //     // Get the starting position
    //     coin.style.left = `${Math.random() * window.innerWidth}px`;
    //     coin.style.top = `${Math.random() * window.innerHeight}px`;

    //     // Get the ending position (position of the balance element)
    //     const balance = document.getElementById('balance');
    //     const balanceRect = balance.getBoundingClientRect();
    //     const endX = balanceRect.left + (balanceRect.width / 2);
    //     const endY = balanceRect.top + (balanceRect.height / 2);

    //     // Calculate the translation distances
    //     const startX = parseFloat(coin.style.left);
    //     const startY = parseFloat(coin.style.top);
    //     const translateX = endX - startX;
    //     const translateY = endY - startY;

    //     // Set custom properties for the ending position
    //     coin.style.setProperty('--translate-x', `${translateX}px`);
    //     coin.style.setProperty('--translate-y', `${translateY + 90}px`);

    //     document.body.appendChild(coin);

    //     setTimeout(() => {
    //         coin.remove();
    //     }, 1000);
    // };

    return (
        <div className='outer_container_thanks' id="niki">
            <div className='container_glow_thanks'>
                <img src={img} className='img_thanks' alt="Thanks" />
                <div className='glow_thanks'></div>
                <div className='coins_thanks'>
                    <img src="https://nikicoin.store/media/images/coin.png" className="coin_img_thanks" alt="Coin-icon" />
                    {coins.toLocaleString('en-US')}
                </div>
                <div className='text_thanks'>{t('thanksNiki.memesBroughtCoins')}</div>
                <button className='button_thanks' onClick={handleClick}>{t('thanksNiki.thanksNiki')}</button>
            </div>
        </div>
    );
}

export default ThanksNiki;
