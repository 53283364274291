import React from 'react';
import { openLink } from '../../Utils/telegramUtils';
import { SendInvite } from '../../http';
function InviteTask({ task, coins, icon, status, link, description }) {

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'K';
        }
        return num.toLocaleString('de-DE');
    };
    const handleClick = () => {
        SendInvite();
        window.Telegram.WebApp.close();
    };

    return (
        <div className={"tasks_con " + (status === "CM" ? "completed" : "")} onClick={handleClick}>
            <div className="iconWrapper">
                <img src={icon} className='img_friends' alt="Task Icon" />
            </div>
            <div className="infoWrapper">
                <h2 className="name">{task}</h2>
                {status === "CM" ? (
                    <svg width="20" height="20" viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.136364 19.4773L6.54545 13.0455L17.0682 23.3864L40.4091 0.136364L46.8636 6.56818L17.0682 36.2045L0.136364 19.4773Z" fill="white" />
                    </svg>
                ) : (
                    <>
                        <span className="coins_reward">+{formatNumber(coins)}<img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_task" alt="Coin-icon" /></span>
                        <div className="description">{description}</div>
                    </>
                )}
            </div>
        </div>
    );
}

export default InviteTask;
