import React from 'react';
import './MainPet.scss';
import dollar_coin from './dollar_coin.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PetStatusHeader({ pet }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(0) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0) + 'K';
        }
        return num;
    };

    return (
        <div className='pet-status-header' onClick={() => navigate("/PetCard")}>
            <div className='avatar-section'>
                <img src={pet.star} className='star-icon'></img>
                <img src={pet.logo_store} alt="Pet" className="pet-avatar" />
            </div>
            <div className='details-section'>
                <div className='pet-name'>{pet.name}</div>
                <div className='pet-level-and-currency'>
                    <img src={dollar_coin} alt="Coin" className="coin-icon" />
                    <div className='currency'>{formatNumber(pet.total_spent)}</div>
                    <div className='level'>{t('petStatusHeader.level')} {pet.level}</div>
                </div>
            </div>
            <div className='additional-info'>
                <div className='currency-additional'>{pet.price_usd}$</div>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.667 14.6384C14.667 14.2848 14.8075 13.9456 15.0575 13.6956C15.3076 13.4455 15.6467 13.3051 16.0003 13.3051C16.3539 13.3051 16.6931 13.4455 16.9431 13.6956C17.1932 13.9456 17.3337 14.2848 17.3337 14.6384V22.6384C17.3337 22.992 17.1932 23.3312 16.9431 23.5812C16.6931 23.8313 16.3539 23.9717 16.0003 23.9717C15.6467 23.9717 15.3076 23.8313 15.0575 23.5812C14.8075 23.3312 14.667 22.992 14.667 22.6384V14.6384ZM16.0003 8.06641C15.6467 8.06641 15.3076 8.20688 15.0575 8.45693C14.8075 8.70698 14.667 9.04612 14.667 9.39974C14.667 9.75336 14.8075 10.0925 15.0575 10.3425C15.3076 10.5926 15.6467 10.7331 16.0003 10.7331C16.3539 10.7331 16.6931 10.5926 16.9431 10.3425C17.1932 10.0925 17.3337 9.75336 17.3337 9.39974C17.3337 9.04612 17.1932 8.70698 16.9431 8.45693C16.6931 8.20688 16.3539 8.06641 16.0003 8.06641Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.0003 2.66602C8.63633 2.66602 2.66699 8.63535 2.66699 15.9993C2.66699 23.3633 8.63633 29.3327 16.0003 29.3327C23.3643 29.3327 29.3337 23.3633 29.3337 15.9993C29.3337 8.63535 23.3643 2.66602 16.0003 2.66602ZM5.33366 15.9993C5.33366 18.8283 6.45747 21.5414 8.45785 23.5418C10.4582 25.5422 13.1714 26.666 16.0003 26.666C18.8293 26.666 21.5424 25.5422 23.5428 23.5418C25.5432 21.5414 26.667 18.8283 26.667 15.9993C26.667 13.1704 25.5432 10.4573 23.5428 8.45688C21.5424 6.45649 18.8293 5.33268 16.0003 5.33268C13.1714 5.33268 10.4582 6.45649 8.45785 8.45688C6.45747 10.4573 5.33366 13.1704 5.33366 15.9993Z" fill="white" />
                </svg>
            </div>
        </div>
    );
}

export default PetStatusHeader;
