import React, { useState } from 'react';
import "./BuyCoins.scss";
import Back from '../Back';
import ComingSoon from '../ComingSoon/ComingSoon';
import offer from "./100.png"
import { GeneratePaymentLink } from '../../http';


function BuyCoins() {
    const [coins, setCoins] = useState(500000);
    const [price, setPrice] = useState(0.5);
    const [show, setShow] = useState(false);

    const handleSliderChange = (event) => {
        const numCoins = parseInt(event.target.value);
        setCoins(numCoins);
        const newPrice = 0.5 + (numCoins - 500000) / 500000 * 0.5;
        setPrice(newPrice.toFixed(1));
    };
    const HandleClick = async () => {
        const data = await GeneratePaymentLink(0.1, "200К")
        console.log(data.payment_link)
        window.Telegram.WebApp.openTelegramLink(data.payment_link);

    };



    return (
        <>
            {show ? <ComingSoon onClick={() => setShow(false)} /> : ""}
            <div className="mobile-prompt-container">
                <Back />


                <div className='try'>

                    <div className='outer_container_play'>
                        <img className='offer' src="https://nikicoin.store/media/images/offer100.png"></img>
                        {/* <div className='container_glow_play'>
                        <div className='buycoins_glow'></div>
                        <img className='buy_coins_img' src='https://nikicoin.store/media/images/coins.png'></img>
                        <input
                            type="range"
                            min="500000"
                            max="10000000"
                            step="500000"
                            value={coins}++
                            onChange={handleSliderChange}
                            className="coin-slider"
                        />


                        <div className='buycoins_coins_container'>
                            <img src='https://nikicoin.store/media/images/nikicoin.png'></img>
                            {coins.toLocaleString()}
                        </div>
                        <div className='buycoins_button' onClick={() => setShow(true)}>
                            {price} $
                        </div>

                    </div> */}
                        <div className='container_glow_buy'>

                            <div className='buycoins_glow'></div>
                            <img className='buy_coins_img' src='https://nikicoin.store/media/images/buycoins.png'></img>

                            <div className='column_buy'>

                                <div className='buycoins_coins_container'>
                                    <img src='https://nikicoin.store/media/images/nikicoin.png'></img>
                                    200,000 +
                                    <img src='https://nikicoin.store/media/images/crystal1.png'></img>
                                    20
                                </div>
                                <div className='buycoins_button' onClick={() => HandleClick()}>
                                    0,1 $
                                </div >

                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </>

    );
}

export default BuyCoins;
