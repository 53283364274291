import React from 'react';
import { useTranslation } from 'react-i18next';
import "./loading.scss";
import Loading_block from './Loading_block';
import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import img5 from "./img5.png";
import { useAppData } from '../../AppContext';

function Loading() {
    const { t } = useTranslation();
    const { progress } = useAppData();

    return (
        <div className='loading'>
            <div className='derishabl_container'>
                <img className='derishabl' src={img5} alt="Derishabl" />
            </div>
            <div className='outer_container_loading'>
                <div className='container_glow_loading'>
                    <div className='glow'></div>
                    <div className='talesTelegramia'>Tales of Telegramia</div>
                    <div className="gradient-text">{t('loading.welcome')}</div>
                    <div className='loading_container'>
                        <Loading_block
                            text={t('loading.tapToFarmTokens')}
                            subtext={t('loading.receiveTokens')}
                            img={img1}
                        />
                        <Loading_block
                            text={t('loading.buyMemeCoins')}
                            subtext={t('loading.buyMemes')}
                            img={img2}
                        />
                        <Loading_block
                            text={t('loading.upgradePet')}
                            subtext={t('loading.upgradePetDescription')}
                            img={img3}
                        />
                        <Loading_block
                            text={t('loading.sellPet')}
                            subtext={t('loading.comingSoon')}
                            img={img4}
                        />
                    </div>
                    <div className='column'>
                        {t('loading.loading')}
                        <div className="loading-bar-container">
                            <div className="loading-bar" style={{ width: `${progress}%` }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loading;
