import React from 'react';
import pointer from "./pointer.png";
import { useNavigate } from 'react-router-dom';
import "./navigate.scss";
import coin from "./coin.png";
import niki from "./niki.png";

function Navigate({ position, navigateTo }) {
    const navigate = useNavigate();

    function handleBack() {
        console.log(navigateTo)
        navigate(navigateTo);
    }

    return (
        <div className={position === "left" ? "pointer left" : "pointer right"} onClick={handleBack}>
            {position === "left" ? (
                <img src={coin} className="img3" alt="Coin" style={{ transform: 'rotate(180deg)' }} />
            ) : (
                <img src={niki} className="img1" alt="Niki" />
            )}
            <img src={pointer} alt="Back" className='img2' />

        </div>
    );
}

export default Navigate;
