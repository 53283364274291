export const LockedIcon = (props) => {
  return (
    <svg
      {...props}
      width="80"
      height="88"
      viewBox="0 0 80 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.2514 36.476V25.96C66.2514 11.66 54.4605 0 40 0C25.5395 0 13.7486 11.66 13.7486 25.96V36.476C5.91769 38.104 0 45.012 0 53.24V70.796C0 80.3 7.78643 88 17.3971 88H62.6474C72.2136 88 80 80.3 80 70.796V53.24C80 45.012 74.0823 38.104 66.2514 36.476ZM43.337 64.592V71.104C43.337 72.908 41.8242 74.404 40 74.404C38.1757 74.404 36.6629 72.908 36.6629 71.104V64.592C33.9488 63.316 32.0801 60.632 32.0801 57.508C32.0801 53.196 35.6396 49.676 40 49.676C44.3604 49.676 47.9199 53.196 47.9199 57.508C47.9199 60.632 46.0512 63.316 43.337 64.592ZM20.4227 36.08V25.96C20.4227 15.268 29.188 6.6 40 6.6C50.812 6.6 59.5773 15.268 59.5773 25.96V36.08H20.4227Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
};

export const TaskOpened = (props) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="88"
        height="88"
        rx="15"
        fill="url(#paint0_linear_22_36)"
        fill-opacity="0.65"
      />
      <path
        opacity="0.85"
        d="M57.7992 31.2477C58.3988 31.818 59.1862 32.0319 59.9358 31.9962H75.1891C76.7257 31.9962 78 30.7843 78 29.3229C78 27.8615 76.7257 26.6496 75.1891 26.6496H66.6066L77.1755 16.5624C78.2624 15.5287 78.2624 13.8178 77.1755 12.7842C76.6133 12.2495 75.9012 12 75.1891 12C74.477 12 73.7649 12.2495 73.2028 12.7842L62.6343 22.8713V14.6733C62.6343 13.2119 61.36 12 59.8234 12C58.2868 12 57.0125 13.2119 57.0125 14.6733V29.1804C56.9375 29.9645 57.1995 30.713 57.7992 31.2477Z"
        fill="white"
      />
      <path
        d="M35 45V48C33.0909 47.6489 32 47.0106 32 46.5C32 45.9894 33.0909 45.3511 35 45Z"
        fill="white"
      />
      <path
        d="M40 54.0211C40 54.6526 39.25 55.4947 38 56V52C39.25 52.5053 40 53.3474 40 54.0211Z"
        fill="white"
      />
      <path
        d="M35.5167 24C20.9075 24 9 35.6681 9 49.9836C9 64.3319 20.9075 76 35.5167 76C50.1259 76 62 64.3319 62 49.9836C62 35.6681 50.1259 24 35.5167 24ZM37.3512 58.9063V59.0698C37.3512 60.083 36.5173 60.9001 35.5167 60.9001C34.4827 60.9001 33.6489 60.083 33.6489 59.0698V58.9063C31.8811 58.5795 30.3135 57.7951 29.2795 56.6511C28.7458 55.9975 28.6791 55.0823 29.1794 54.396C29.6464 53.7096 30.5469 53.4481 31.3474 53.7423L33.6489 54.6248V51.6178C30.4135 51.0295 28.0787 48.9378 28.0787 46.3557C28.0787 43.7737 30.4135 41.6819 33.6489 41.0936V40.8975C33.6489 39.8843 34.4827 39.0672 35.5167 39.0672C36.5173 39.0672 37.3512 39.8843 37.3512 40.8975V41.0609C39.1523 41.3878 40.72 42.2049 41.7206 43.3488C42.2876 43.9698 42.321 44.885 41.854 45.5713C41.4871 46.0616 40.9201 46.3557 40.3197 46.3557C40.0862 46.3557 39.8861 46.323 39.6526 46.225L37.3512 45.3752V48.3495C40.5865 48.9378 42.9213 51.0295 42.9213 53.6442C42.9213 56.2263 40.5865 58.318 37.3512 58.9063Z"
        fill="white"
        fill-opacity="0.85"
      />
      <path
        d="M40 54.0211C40 54.6526 39.25 55.4947 38 56V52C39.25 52.5053 40 53.3474 40 54.0211Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_22_36"
          x1="44"
          y1="0"
          x2="44"
          y2="88"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#61FF00" />
          <stop offset="1" stop-color="#215800" />
        </linearGradient>
      </defs>
    </svg>
  );
};
