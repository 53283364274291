import React from 'react';

const Pet = ({ pet, onSelectPet, isSelected }) => {
    const handleClick = () => {
        if (pet.status === "CS") {
            window.navigator.vibrate = window.navigator.vibrate || window.navigator.webkitVibrate || window.navigator.mozVibrate || window.navigator.msVibrate;
            if (navigator.vibrate) {
                window.navigator.vibrate(1000);
                console.log("123")

            }
        } else {
            onSelectPet();
        }
    };

    return (
        <div onClick={handleClick} className={`pet-item ${isSelected ? 'selected' : ''}${pet.status === "CS" ? "unavailable" : ""}`}>
            <div className='glow_unavailable'></div>
            <img src={pet.logo_with_background} className="pet_choosing_img" alt="Available Pet" />
        </div>
    );
};

export default Pet;
