import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Back from '../Back';
import ComingSoon from '../ComingSoon/ComingSoon';

import coinImg from '../../images/icons/coin.png';
import gameBoomImg from '../../images/games/nikiboom.png';
import controller from './controller.png';
import kiki from "./kiki.png";
import newImg from "./new.png"
import './games.scss';
import { openLink } from '../../Utils/telegramUtils';
export const Games = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    return (
        <>
            {show ? <ComingSoon onClick={() => setShow(false)} /> : null}

            <div className='outer-container_games'>
                <div className='container_glow_games'>
                    <Back />
                    <div className="tab_content_games">
                        <div className="tab-header">
                            <div className="tab-header-background"></div>
                            <img src={controller} className="tab-img" draggable="false" />
                            <h2 className="tab-title">{t('games.playOurGames')}</h2>
                        </div>
                        <h3 style={{ marginLeft: "-20px" }}>{t('games.ourGames')}</h3>
                        <div>

                            <div className="game_container">
                                <div className="nikiboom-game-info">
                                    <img src={newImg} className='new'></img>
                                    <img src={kiki} alt="Niki Boom" />
                                    <p>KIKI Cat Blades <br></br> Cat KIKI bravely rushed into battle with monsters<img src={coinImg} className="coin-icon" alt="Coin-icon" /></p>
                                </div>
                                <button className="button_play_games button-purple" onClick={()=>openLink("https://t.me/CatsBladesBot/CatsBlades")}>Play</button>
                            </div>
                            <div className="game_container">
                                <div className="nikiboom-game-info">
                                    <img src={gameBoomImg} alt="Niki Boom" />
                                    <p>{t('games.nikiBoom')}<img src={coinImg} className="coin-icon" alt="Coin-icon" /></p>
                                </div>
                                <button className="button_play_games button-purple" onClick={()=>openLink("https://t.me/playdeckbot?start=gi0uL")}>{t('games.play')}</button>
                            </div>
                           

                            {/* <div className="game_container">
                                <div className="nikiboom-game-info">
                                    <img src={img2} alt="Meme Guys" />
                                    <p>{t('games.memeGuys')}</p>
                                </div>
                                <button className="button_play_games" onClick={() => setShow(true)}>{t('games.comingSoon')}</button>
                            </div> */}

                            {/* <div className="game_container">
                                <div className="nikiboom-game-info">
                                    <img src={img1} alt="Niki World" />
                                    <p>{t('games.nikiWorld')}</p>
                                </div>
                                <button className="button_play_games" onClick={() => setShow(true)}>{t('games.comingSoon')}</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
