export function openLink(link) {
    console.log('Attempting to open link:', link);
    if (window.Telegram.WebApp) {
        if (link.startsWith('https://t.me')) {
            console.log('Opening Telegram link:', link);
            window.Telegram.WebApp.openTelegramLink(link);
        } else {
            console.log('Opening regular link:', link);
            window.Telegram.WebApp.openLink(link);
        }
    } else {
        console.log('Telegram WebApp is not available.');
    }
}
