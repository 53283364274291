import React, { useState } from 'react';
import '../Instruction/instruction.scss';
import Countdown from '../Instruction/Countdown';
import listing from './listing.png'
function ListingLoading(props) {
 
    return (
        <div className='outer_container_listing'>
            <div className='container_glow_listing'>
                {/* <span className="close" onClick={() => setShow(false)}>&times;</span> */}

                <div className='flex_instruction'>
                    <img src='https://nikicoin-bucket.s3.eu-north-1.amazonaws.com/newcoin+NIKI/nikicoin00.png'></img>
                    <h1 style={{ textAlign: "center", color: "white" }}>LISTING TIME</h1>
                </div>
                <Countdown />

                <img className='img_listing' src={listing}></img>
            </div>
        </div>
    );
}

export default ListingLoading;
