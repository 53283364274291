import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  getUserInfo,
  getUserUpgrades,
  getUserSpecials,
  getUserRefferedCount,
  sendSpecial,
  sendCoinsAndEnergy,
  sendUpgrade,
  sendJoinTeam,
  sendLeaveTeam,
} from "./http";
import {
  getPlayerPet,
  getAllPets,
  getPlayerFood,
  getPlayerMemes,
  getUserTasks,
  getAllLeagues,
  getUserPromotions,
  getUserDaylys
} from "./http";
import Loading from "./Components/Loading/Loading";
import Oops from "./Components/Oops/Oops";
import ListingLoading from "./Components/Listing/ListingLoading";

export const AppContext = createContext({
  USER_ID: null,
  user: null,
  coins: 10,
  energy: 0,
  clickCost: 1,
  energyLimit: 0,
  energyRechargeDelay: 1,
  refferedCount: 0,
  upgrades: null,
  specials: null,
  specialMode: null,
  allPets: [],
  pet: [],
  food: [],
  memes: [],
  passiveIncome: 0,
  passiveIncomePerHour: 0,
  total_coins_earned: 0,
  tasks: [],
  leagues: [],
});
const DelayedComponent = () => {
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (!showComponent) {
    return <ListingLoading />;
  }

  return (
    <div>
      <Oops />
    </div>
  );
};
export const useAppData = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [coins, setCoins] = useState(0);
  const [diamonds, setDiamonds] = useState(0);
  const [total_coins_earned, setTotal_coins_earned] = useState(0);
  const [passiveIncome, setpassiveIncome] = useState(0);
  const [passiveIncomePerHour, setpassiveIncomePerHour] = useState(0);
  const [refferedCount, setRefferedCount] = useState(0);
  const [refferedCoinsCount, setRefferedCoinsCount] = useState(0);
  const [energy, setEnergy] = useState(0);
  const [isFirstTime, setIsFisrstTime] = useState(false);
  const [clickCost, setClickCost] = useState(1);
  const [energyLimit, setEnergyLimit] = useState(0);
  const [userPhoto, setUserPhoto] = useState("");
  const [energyRechargeDelay, setEnergyRechargeDelay] = useState(1);
  const [allPets, setAllPets] = useState([]);
  const [Pet, setPet] = useState([]);
  const [food, setFood] = useState([]);
  const [memes, setMemes] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [team, setTeam] = useState([]);
  const [progress, setProgress] = useState(1);
  const [closestQuizTime, setClosestQuizTimes] = useState(null);
  const [showNavigate, setShowNavigate] = useState(true);
  const totalRequests = 9;
  const [dailys, setDailys] = useState([]);

  const dollar_coin =
    "https://nikicoin.store/media/images/coin.png";
  const [triggerRender, setTriggerRender] = useState(false);

  const [upgrades, setUpgrades] = useState([
    { id: "multitap", level: 1 },
    { id: "rechargingSpeed", level: 1 },
    { id: "energyLimit", level: 1 },
  ]);

  const [specials, setSpecials] = useState([
    { id: "rocket", count: 1 },
    { id: "bomb", count: 1 },
    { id: "shuriken", count: 1 },
    { id: "full_energy", count: 1 },
  ]);

  const [specialMode, setSpecialMode] = useState(null);

  const updateProgress = () => {
    setProgress((prevProgress) => {
      const newProgress = prevProgress + 100 / totalRequests;
      return newProgress > 100 ? 100 : newProgress;
    });
  };

  const [specialModeTimer, setSpecialModeTimer] = useState(null);

  const handleEditCoinsClick = (value) => {
    if (value > 0) {
      setTotal_coins_earned(total_coins_earned + value);
    }
    setCoins(coins + value);
  };

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.setHeaderColor("#000000");
    }
  }, []);

  const activateSpecial = async (specialId) => {
    const specialIndex = specials.findIndex(
      (special) => special.id === specialId
    );

    if (specials[specialIndex].count > 0) {
      const updatedSpecials = [...specials];
      updatedSpecials[specialIndex].count -= 1;
      setSpecials(updatedSpecials);

      await sendSpecial(specialId);

      if (specialId === "rocket") {
        enableSpecialMode(specialId, 10, 10);
      } else if (specialId === "bomb") {
        enableSpecialMode(specialId, 50, 10);
      } else if (specialId === "shuriken") {
        enableSpecialMode(specialId, 150, 10);
      } else if (specialId === "full_energy") {
        enableSpecialMode(specialId, 0, 3);
      }
    } else {
      console.log(
        `Special item ${specialId} is not available or out of stock.`
      );
    }
  };

  const enableSpecialMode = (mode, bonus, duration) => {
    disableSpecialMode();
    setSpecialMode({ mode, bonus });

    const timer = setTimeout(() => {
      disableSpecialMode();
    }, duration * 1000);
    setSpecialModeTimer(timer);
  };

  const disableSpecialMode = () => {
    setSpecialMode(null);
    clearTimeout(specialModeTimer);
  };

  const handleEditDiamonds = (value) => {
    console.log(value)
    setDiamonds(diamonds + value / 2);
  };

  const handleEditCoins = (value) => {
    let targetCoins = 0;
    if (value === passiveIncome) {
      sendCoinsAndEnergy(value, 0);
      targetCoins = coins + value;
    } else {
      targetCoins = coins + value;
    }

    const totalDuration = 1000;
    let magnitude = Math.floor(Math.log10(Math.abs(value))) - 2;
    let step = magnitude > 0 ? Math.pow(10, magnitude) : 1;
    step = value > 0 ? step : -step;

    const stepsCount = Math.floor(Math.abs(value) / Math.abs(step));
    const lastStep = value % step;

    const interval = totalDuration / (stepsCount + (lastStep !== 0 ? 1 : 0));

    const handleStep = () => {
      setCoins((prev) => {
        const nextValue = prev + step;
        if (
          (step > 0 && nextValue >= targetCoins) ||
          (step < 0 && nextValue <= targetCoins)
        ) {
          return targetCoins;
        } else {
          setTimeout(handleStep, interval);
          return nextValue;
        }
      });
    };

    handleStep();
  };

  const handleEditEnergy = (value) => {
    if (energy <= energyLimit && energy > 0) {
      setEnergy(energy + value);
    }
  };

  const updateUpgradesValue = () => {
    if (upgrades && upgrades.length > 0) {
      upgrades.forEach((upgrade) => {
        if (upgrade.id === "energyLimit") {
          setEnergyLimit(upgrade.level * 1000);
        } else if (upgrade.id === "multitap") {
          setClickCost(upgrade.level);
        } else if (upgrade.id === "rechargingSpeed") {
          setEnergyRechargeDelay(upgrade.level);
        }
      });
    }
  };

  const getUserInfoData = async () => {
    const data = await getUserInfo();
    updateProgress();
    setUser(data);
    setCoins(data.coins_count);
    setEnergy(data.energy_count);
    setTotal_coins_earned(data.total_coins_earned);
    setpassiveIncome(data.passive_income);
    setpassiveIncomePerHour(data.passive_income_per_hour);
    setUserPhoto(data.profile_photo);
    setIsFisrstTime(data.is_first_time_logged);
    setDiamonds(data.diamonds_balance)
  };

  const getUserUpgradesData = async () => {
    const data = await getUserUpgrades();
    updateProgress();

    if (data) {
      const formattedUpgrades = [
        { id: "multitap", level: data.multitap_level || 0 },
        { id: "rechargingSpeed", level: data.recharging_speed_level || 0 },
        { id: "energyLimit", level: data.energy_limit_level || 0 },
      ];
      setUpgrades(formattedUpgrades);
    } else {
      setUpgrades([]);
    }
  };

  const purchaiseUpgrade = (upgradeId) => {
    const upgradeIndex = upgrades.findIndex(
      (upgrade) => upgrade.id === upgradeId
    );

    const newUpgrades = [...upgrades];
    newUpgrades[upgradeIndex].level += 1;
    setUpgrades(newUpgrades);

    sendUpgrade(upgradeId);
  };

  const getUserRefferedCountData = async () => {
    const data = await getUserRefferedCount();
    updateProgress();

    if (data) {
      setRefferedCount(data.friends_reffered_count);
      setRefferedCoinsCount(data.total_earned_by_friends);
    }
  };

  const getUserSpecialsData = async () => {
    const data = await getUserSpecials();
    updateProgress();

    if (data) {
      const formattedSpecials = Object.entries(data).map(([key, value]) => ({
        id: key,
        count: value,
      }));

      setSpecials(formattedSpecials);
    }
  };

  const joinTeam = (teamId) => {
    setUser((prevUser) => ({
      ...prevUser,
      team: teamId,
    }));

    sendJoinTeam(teamId);
  };

  const exitTeam = () => {
    setUser((prevUser) => ({
      ...prevUser,
      team: null,
    }));

    sendLeaveTeam();
  };

  const getAllPetstData = async () => {
    const data = await getAllPets();
    updateProgress();
    setAllPets(data.pets);
  };

  const getPlayerPetData = async () => {
    const data = await getPlayerPet();
    updateProgress();
    setPet(data);
    setClosestQuizTimes(data.closest_quiz_time)
    return data;
  };

  const getPlayerFoodData = async () => {
    const data = await getPlayerFood();
    updateProgress();

    setFood(data);
  };

  const getPlayerMemesData = async () => {
    const data = await getPlayerMemes();
    console.log(data)
    updateProgress();

    setMemes(data.memes);
  };

  const getUserDaylysData = async () => {
    const data = await getUserDaylys();
    updateProgress();

    setDailys(data.daily_tasks);
  }


  const getUserTasksData = async () => {
    const data = await getUserTasks();
    updateProgress();

    setTasks(data.tasks);
  };

  const getAllLeaguesData = async () => {
    const data = await getAllLeagues();
    updateProgress();
    setLeagues(data.leagues);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await Promise.all([
          getUserInfoData(),
          getUserUpgradesData(),
          getUserSpecialsData(),
          getUserRefferedCountData(),
          getAllPetstData(),
          getPlayerPetData(),
          getPlayerFoodData(),
          getPlayerMemesData(),
          getUserTasksData(),
          getAllLeaguesData(),
          getUserDaylysData()
        ]);

        setPet(results[5]);
        setFood(results[6]);
        setMemes(results[7]);
        setLeagues(results[8].leagues);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [triggerRender]);

  useEffect(() => {
    if (upgrades && upgrades.length > 0) {
      updateUpgradesValue();
    }
  }, [upgrades]);

  if (!user) {
    return <DelayedComponent />;
  }

  return (
    <AppContext.Provider
      value={{
        user,
        coins: coins - passiveIncome,
        handleEditCoins,
        handleEditDiamonds,
        energy,
        diamonds,
        handleEditEnergy,
        energyLimit,
        energyRechargeDelay,
        clickCost,
        upgrades,
        specials,
        purchaiseUpgrade,
        specialMode,
        activateSpecial,
        joinTeam,
        exitTeam,
        allPets,
        Pet,
        food,
        memes,
        passiveIncome,
        passiveIncomePerHour,
        total_coins_earned,
        tasks,
        progress,
        leagues,
        refferedCount,
        getPlayerPetData,
        dollar_coin,
        setpassiveIncomePerHour,
        setpassiveIncome,
        setEnergy,
        handleEditCoinsClick,
        userPhoto,
        setTriggerRender,
        team,
        setPet,
        setTotal_coins_earned,
        refferedCoinsCount,
        energyRechargeDelay,
        isFirstTime,
        closestQuizTime,
        setClosestQuizTimes,
        showNavigate,
        setShowNavigate,
        dailys

      }}
    >
      {loading ? <Loading /> : children}
    </AppContext.Provider>
  );
};
