import React, { useState, useEffect } from 'react';
import StatBlock from '../StatsMain/StatBlock';
import { useAppData } from '../../AppContext';
import "./shop.scss";
import bolt from "./bolt.png";
import niki from "./Niki.png";
import arrow from "./Arrow.png";
import PetCard from '../Tasks/PetCard';
import Food from './Food';
import { useLocation, useNavigate } from 'react-router-dom';
import { buyFood, getPlayerFood, getPlayerMemes, selectFood, manageMeme } from '../../http';
import ComingSoon from '../ComingSoon/ComingSoon';
import Back from '../Back';
import img from "./img1.png";
import pointer from "./Vector.svg";
import { useTranslation } from 'react-i18next';

function Shop() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { coins, Pet, setpassiveIncomePerHour, memes, setTriggerRender } = useAppData();
    const [activeTab, setActiveTab] = useState('niki');
    const [show, setShow] = useState(false);
    const [palyerFood, setPalyerFood] = useState([]);
    const [palyerMemes, setpalyerMemes] = useState(memes);
    const [selectedFood, setSelectedFood] = useState(null);
    const [selectedMeme, setSelectedMeme] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('/shop/pets')) {
            setActiveTab('pets');
        } else if (location.pathname.includes('/shop/niki')) {
            setActiveTab('niki');
        } else if (location.pathname.includes('/shop/craft')) {
            setActiveTab('craft');
        }
    }, [location]);

    const getPlayerFoodData = async () => {
        const data = await getPlayerFood();
        setPalyerFood(data.food);
    }

    const getPlayerMemesData = async () => {
        const data = await getPlayerMemes();
        console.log(data);
        setpalyerMemes(data.memes);
    }

    useEffect(() => {
        const fetchData = async () => {
            await getPlayerFoodData();
            await getPlayerMemesData();
        };

        fetchData();
    }, []);

    const handleFoodClick = (foodItem) => {
        setSelectedFood(foodItem);
    };

    const handleCloseModal = () => {
        setSelectedFood(null);
        setSelectedMeme(null);
    };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const handleMemeClick = (memeItem) => {
        setSelectedMeme(memeItem);
    };

    const handleManageMeme = async (memeId, cost) => {
        await manageMeme(memeId);
        handleCloseModal();
        setpassiveIncomePerHour(prev => prev + cost);
        await getPlayerMemesData();
    };

    const handleBuyFood = async (foodId) => {
        await buyFood(foodId);
        await selectFood(foodId);
        handleCloseModal();
        await getPlayerFoodData();
    };

    const handleFoodSelect = async (foodId) => {
        await selectFood(foodId);
        setTriggerRender(true);
        handleCloseModal();
        await getPlayerFoodData();
    };

    const handleComing = () => {
        setShow(false);
        setActiveTab("pets");
    };

    return (
        <div>
            <Back />
            {show && <ComingSoon onClick={handleComing} />}

            <div className='outer-container_boost'>
                <div className='container_glow_shop'>
                    <StatBlock />
                    <div className='coins_container_shop'>
                        <div className='coins_text'>{t('shop.yourBalance')}</div>
                        <div className="stats">
                            <div id="balance" className="coin-count flex_center">
                                <img src="https://nikicoin.store/media/images/coin.png" className="coin_balance_shop" alt="Coin-icon" /> {coins.toLocaleString('en-US')}
                            </div>
                        </div>
                    </div>

                    <div className="navigationTab">
                        <button className={`navButton ${activeTab === 'pets' ? 'selected' : ''}`}
                            onClick={() => handleTabClick('pets')}>
                            <img src="https://nikicoin.store/media/images/pet.png" alt="Upgrade" />
                            <span>{t('shop.pets')}</span>
                        </button>

                        {Pet ? (
                            <button className={`navButton ${activeTab === 'niki' ? 'selected' : ''}`}
                                onClick={() => handleTabClick('niki')}>
                                <img src={niki} alt="NIKI Shop" />
                                <span>{t('shop.nikiShop')}</span>
                            </button>
                        ) : (
                            <button className={`navButton ${activeTab === 'niki' ? 'selected' : ''}`}>
                                <img src={niki} alt="NIKI Shop" />
                                <span className='craft_container'>
                                    <span>{t('shop.nikiShop')}</span>
                                    <span className='coming_soon_text'>{t('shop.buyPet')}</span>
                                </span>
                            </button>
                        )}

                        <button className={`navButton ${activeTab === 'craft' ? 'selected' : ''}`}
                            onClick={() => { setShow(true); setActiveTab("craft"); }}>
                            <img src={bolt} alt="Craft" />
                            <span className='craft_container'>
                                <span>{t('shop.craft')}</span>
                                <span className='coming_soon_text'>{t('shop.comingSoon')}</span>
                            </span>
                        </button>
                    </div>

                    {activeTab === 'niki' && (
                        <>
                            <div className='coming_title'>{t('shop.petHome')}</div>
                            <div className='coming_pets'>
                                <div className='coming_pet' onClick={() => setShow(true)}>
                                    <img className='coming_pet_img' src="https://nikicoin.store/media/images/super_food.png" alt="Super NIKI Pet Food" />
                                    <div className='column'>
                                        <div className='coming_pet_text' style={{ textAlign: "center" }}>{t('shop.superNikiFood')}</div>
                                        <div className='coming_soon_text'>{t('shop.comingSoon')}</div>
                                    </div>
                                    <img className='pointer_shop' src={pointer} alt="Pointer" />
                                </div>

                                <div className='coming_pet' onClick={() => navigate("/buy")}>
                                    <img className='offer_shop' src="https://nikicoin.store/media/images/offer100.png" alt="Offer" />
                                    <img className='coming_pet_img' src="https://nikicoin.store/media/images/coins.png" alt="NIKI Coins" />
                                    <div className='column'>
                                        <div className='coming_pet_text'>{t('shop.nikiCoins')}</div>
                                    </div>
                                    <img className='pointer_shop' src={pointer} alt="Pointer" />
                                </div>
                            </div>
                            <div className='shop_grid'>
                                {palyerFood.map((item) => (
                                    <Food
                                        key={item.id}
                                        name={item.name}
                                        logo={item.logo}
                                        coins_cost={item.coins_cost}
                                        is_owned={item.is_owned}
                                        is_selected={item.is_selected}
                                        status={item.status}
                                        onFoodClick={() => handleFoodClick(item)}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    {activeTab === 'pets' && (
                        <>
                            <div className='coming_title'>{t('shop.petHome')}</div>
                            <div className='coming_pets'>
                                <div className='coming_pet' onClick={() => setShow(true)}>
                                    <img className='coming_pet_img' src="https://nikicoin.store/media/images/pet.png" alt="2nd PET" />
                                    <div className='column'>
                                        <div className='coming_pet_text'>{t('shop.secondPet')}</div>
                                        <div className='coming_soon_text'>{t('shop.comingSoon')}</div>
                                    </div>
                                    <img className='pointer_shop' src={pointer} alt="Pointer" />
                                </div>

                                <div className='coming_pet' onClick={() => setShow(true)}>
                                    <img className='coming_pet_img' src="https://nikicoin.store/media/images/pet.png" alt="3rd PET" />
                                    <div className='column'>
                                        <div className='coming_pet_text'>{t('shop.thirdPet')}</div>
                                        <div className='coming_soon_text'>{t('shop.comingSoon')}</div>
                                    </div>
                                    <img className='pointer_shop' src={pointer} alt="Pointer" />
                                </div>
                            </div>
                            <div className='coming_title'>{t('shop.megaMarket')}</div>
                            <div className='mega_market_container'>
                                <div className='background_mega'></div>
                                <div className='background_mega_coming'>
                                    <div className='text_coming_mega'>{t('shop.comingSoon')}</div>
                                    <img src={img} className='img_coming_shop' alt="Coming soon" />
                                </div>
                            </div>
                        </>
                    )}

                    {selectedFood && (
                        <div className="modal">
                            <div className='container_glow_modal'>
                                <div className="modal-container">
                                    <span className="close" onClick={handleCloseModal}>&times;</span>
                                    <div className="modal-content_shop">
                                        <span className="close" onClick={handleCloseModal}>&times;</span>
                                        <img src={selectedFood.logo} alt={selectedFood.name} className="modal-image_shop" />
                                        <h3>{selectedFood.name}</h3>
                                        {selectedFood.is_owned === "true" ? (
                                            <div>
                                                <div className='text-modal'>{t('shop.youOwnThisFood')}</div>
                                                <button className='button-long' onClick={() => handleFoodSelect(selectedFood.id)}>{t('shop.select')}</button>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className='text-modal'>{t('shop.youWillFeedPet')}</div>
                                                <div className='coins_modal_shop'>{selectedFood.coins_cost} <img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_shop_modal" alt="Coin-icon" /></div>
                                                <button className='button-long' onClick={() => handleBuyFood(selectedFood.id)}>{t('shop.buy')}</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Shop;
