import React from 'react';
import './shop.scss';
import { useTranslation } from 'react-i18next';

const Food = ({ name, logo, coins_cost, is_owned, is_selected, status, onFoodClick }) => {
    const { t } = useTranslation();
    const available = status === "AV" ? "" : "disabled";
    const selectedClass = is_selected === "true" ? "selected" : "";

    return (
        <div className={`foodCard ${available} ${is_owned === "true" ? "owned" : ""} ${is_selected === "true" ? "selected" : ""}`} onClick={onFoodClick}>
            <div className="iconWrapper">
                <img src={logo} alt={name} className="petIcon" />
            </div>
            <div className="infoWrapper">
                <h2 className="foodName">{name}</h2>
                {is_selected === "true" ? (
                    <div style={{ color: "#939393" }}>{t('food.selected')}</div>
                ) : (
                    <>
                        {is_owned === "true" ? (
                            <span className="coins">{t('food.owned')}</span>
                        ) : (
                            <>
                                {status === "AV" ? (
                                    <span className="coins flex_center">{coins_cost} <img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_task" alt="Coin-icon" /></span>
                                ) : (
                                    <span className="coming_soon">{t('food.comingSoon')}</span>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>

            {is_selected !== "true" && (
                <svg width="19" height="41" viewBox="0 0 19 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 39L17 20.5L2 2" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            )}
        </div>
    );
};

export default Food;
