import React from 'react';
import { useAppData } from '../../AppContext';
import giftImg from '../../images/icons/gift.png';
import coinImg from '../../images/icons/coin.png';
import premiumImg from '../../images/icons/premium.png';
import { useNavigate } from 'react-router-dom';
import Back from '../Back';
import Friends_task from './Friends_task';
import './friends.scss';
import { SendInvite } from '../../http';
import { useTranslation } from 'react-i18next';

export const Frens = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { refferedCount, TotalEarndFromFriends, refferedCoinsCount } = useAppData();

    const friendsTasks = [
        { id: 1, task: t('tasks.task1'), friendsRequired: 3, coins: 50000, emoji: "https://nikicoin.store/media/images/3friends.png", color: "purple", league: null },
        { id: 2, task: t('tasks.task2'), friendsRequired: 10, coins: 100000, emoji: "https://nikicoin.store/media/images/10friends.png", color: "orange", league: null },
        { id: 3, task: t('tasks.task3'), friendsRequired: 50, coins: 500000, emoji: "https://nikicoin.store/media/images/50friends.png", color: "silver", league: null },
        { id: 4, task: t('tasks.task4'), friendsRequired: 100, coins: 1000000, emoji: "https://nikicoin.store/media/images/100friends.png", color: "gold", league: null },
        { id: 5, task: t('tasks.task5'), friendsRequired: 500, coins: 10000000, emoji: "https://nikicoin.store/media/images/500friends.png", color: "blue", league: null },
        { id: 6, task: t('tasks.task6'), friendsRequired: 1000, coins: 100000000, emoji: "https://nikicoin.store/media/images/1000friends.png", color: "yellow", league: null }
    ];

    const formatNumber = (num) => num >= 1000000 ? (num / 1000000).toFixed() + 'M' : num >= 1000 ? (num / 1000).toFixed() + 'K' : num;

    const handleCLick = () => {
        SendInvite();
        window.Telegram.WebApp.close();
    };

    return (
        <div className='outer-container_boost'>
            <div className='container_glow_friends'>
                <Back />
                <div className="tab-header">
                    <div className="tab-header-background frens-header-background"></div>
                    <img src="https://nikicoin.store/media/images/boxfriends.png" draggable="false" />
                    {/* <h2 className="tab-title">{t('friends.title')}</h2> */}
                </div>
                <div className="tab_block_friends background_block row gradient_block" onClick={() => navigate("/friendsList")}>
                    <div className="frens-coins-earned flex_center">+{formatNumber(refferedCoinsCount)}<img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_task" alt="Coin-icon" /></div>
                    <div>{t('friends.friendsAdded', { count: refferedCount })}</div>
                    <button className="tab-button-arrow">›</button>
                </div>

                <div className="tab_block_friends background_block">
                    <div className="tab-subblock">
                        <img src={coinImg} className="coin_friends" />
                        <div>
                            <div className="tab-block-title name">{t('friends.addFriend')}</div>
                            <div className='row'>
                                <div className='flex_center'>{t('friends.you', { count: 5000 })}<img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_task" alt="Coin-icon" /></div>
                                <div className='ml-10 flex_center'>{t('friends.friend', { count: 5000 })}<img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_task" alt="Coin-icon" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-subblock">
                        <img src={premiumImg} className=" star" />
                        <div>
                            <div className="tab-block-title">{t('friends.addPremiumFriend')}</div>
                            <div className='row'>
                                <div className='flex_center'>{t('friends.you', { count: 25000 })}<img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_task" alt="Coin-icon" /></div>
                                <div className='ml-10 flex_center'>{t('friends.friend', { count: 25000 })}<img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_task" alt="Coin-icon" /></div>
                            </div>
                        </div>
                    </div>
                    <button className="button_invite button-purplr" onClick={handleCLick}>{t('friends.invite')}</button>
                </div>
                <div className='friends_grid'>
                    {friendsTasks.map(task => (
                        <Friends_task
                            key={task.id}
                            task={task.task}
                            coins={formatNumber(task.coins)}
                            emoji={task.emoji}
                            color={task.color}
                            league={task.league}
                            isCompleted={refferedCount >= task.friendsRequired}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
