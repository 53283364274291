import React, { useState } from 'react';
import { useAppData } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import Back from '../Back';
import './boost.scss';
import { useTranslation } from 'react-i18next';
import NotEnoughMoney from '../Pet/MainPet/NotEnoughMoney/NotEnoughMoney';

import bomb from "./bomb.png"
import energy from "./energy.png"
import rocket from './rocket.png';
import shuriken from './shuriken1.png';

import boostcoin from './boostcoin.png';


import energylimit from './energylimit.png';
import multitap from './multitap.png';
import rechargingspeed from './rechargingspeed.png';

const UPGRADE_PRICES = {
    1: 10000,
    2: 20000,
    3: 80000,
    4: 160000,
    5: 320000,
    6: 640000,
    7: 1280000,
    8: 2560000,
    9: 5120000,
    10: 10240000,
    11: 20480000,
    12: 40960000,
    13: 81920000,
    14: 163840000,
    15: 327680000,
    16: 655360000,
};

export const Boost = () => {
    const { t, i18n } = useTranslation();
    const { coins, upgrades, specials, handleEditCoins, purchaiseUpgrade, activateSpecial, dollar_coin } = useAppData();
    const [selectedUpgrade, setSelectedUpgrade] = useState(null);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);


    const handleUpgradeClick = (upgrade) => {
        setSelectedUpgrade(upgrade);
    };

    const handleCloseModal = () => {
        setSelectedUpgrade(null);
    };

    const handleActivateSpecial = (specialId) => {
        activateSpecial(specialId);
        navigate('/');
    };

    const handleUpgrade = () => {
        if (selectedUpgrade) {
            const upgradeCost = getUpgradeCost(selectedUpgrade.level);
            if (coins >= upgradeCost) {
                purchaiseUpgrade(selectedUpgrade.id);
                handleEditCoins(-upgradeCost);
            }
            else {
                setShowError(true)
            }
        }

        setSelectedUpgrade(null);
    };

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0) + 'K';
        }
        return num;
    };

    const getUpgradeCost = (upgradeLevel) => {
        return UPGRADE_PRICES[upgradeLevel] || null;
    };

    const formatSpecialName = (name, t) => {
        return t(`specials.${name}`);
    };

    return (
        <div className='outer-container_boost'>
            {showError ? <NotEnoughMoney /> : ""}
            <div className='container_glow'>
                <div className="tab-header">
                    <div className="tab-header-background shop-header-background"></div>
                    <img className='rocket_boost' src={boostcoin}></img>
                </div>
                <div className="tab-section-title">{t('boost.freeDailyBoosts')}</div>
                <div className="shop-spcial-container">
                    {specials.map((special) => (
                        <div key={special.id} className="tab-block" onClick={() => handleActivateSpecial(special.id)}>
                            <div className='d-flex' style={{ gap: "10px" }}>
                                <img src={getSpecialImage(special.id)} className='boost_img' alt={special.id}></img>
                                <div>
                                    <p className="shop-special-name">{formatSpecialName(special.id, t)}</p>
                                    <div id={`shop-special-count-${special.id}`} className="shop-special-count">
                                        <span>{special.count}</span> {t('boost.available')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="tab-block" style={{ marginTop: "20px" }}>
                    {upgrades.map((upgrade) => (
                        <div key={upgrade.id} className="tab-subblock" onClick={() => handleUpgradeClick(upgrade)}>
                            <img className="shop-upgrade-img-case" src={getUpgradeInfo(upgrade.id, t).icon}>

                            </img>
                            <div>
                                <div className="shop-upgrade-name">{getUpgradeInfo(upgrade.id, t).title}</div>
                                <div className="shop-upgrade-description">
                                    {upgrade.level !== 16 ? (
                                        <div>
                                            <span className='row'>
                                                {formatNumber(getUpgradeCost(upgrade.level))} <img style={{ width: "20px" }} src={dollar_coin} alt="coin" />{` / ${upgrade.level} lvl`}
                                            </span>
                                        </div>
                                    ) : (
                                        <div>{t('boost.maxLevelReached')}</div>
                                    )}
                                </div>
                            </div>
                            {getUpgradeCost(upgrade.level) !== null && (
                                <button className="tab-button-arrow">›</button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <Back />
            {selectedUpgrade && (
                <div className="modal">
                    <div className='container_glow_modal'>
                        <div className="modal-container">
                            <span className="close" onClick={handleCloseModal}>&times;</span>
                            <div className="modal-content">
                                <div className="modal-info">
                                    <img className="modal-image" src={getUpgradeInfo(selectedUpgrade.id, t).icon}>

                                    </img>
                                    <h2 className='modal-title'>{getUpgradeInfo(selectedUpgrade.id, t).title}</h2>
                                    <p>{getUpgradeInfo(selectedUpgrade.id, t).description}</p>
                                    <p>{getUpgradeInfo(selectedUpgrade.id, t).effect}</p>
                                    {selectedUpgrade.level !== 16 && (
                                        <button className='button-long' onClick={handleUpgrade}>{t('boost.buy')}</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const getSpecialImage = (name) => {
    switch (name) {
        case "rocket":
            return rocket;
        case "bomb":
            return bomb;
        case "shuriken":
            return shuriken;
        case "full_energy":
            return energy;
        default:
            return null;
    }
};

const getUpgradeInfo = (name, t) => {
    switch (name) {
        case "multitap":
            return {
                name: t('upgrades.multitap.name'),
                icon: multitap,
                title: t('upgrades.multitap.title'),
                description: t('upgrades.multitap.description'),
                effect: t('upgrades.multitap.effect')
            };
        case "rechargingSpeed":
            return {
                name: t('upgrades.rechargingSpeed.name'),
                icon: rechargingspeed,
                title: t('upgrades.rechargingSpeed.title'),
                description: t('upgrades.rechargingSpeed.description'),
                effect: t('upgrades.rechargingSpeed.effect')
            };
        case "energyLimit":
            return {
                name: t('upgrades.energyLimit.name'),
                icon: energylimit,
                title: t('upgrades.energyLimit.title'),
                description: t('upgrades.energyLimit.description'),
                effect: t('upgrades.energyLimit.effect')
            };
        default:
            return null;
    }
};
