import React, { useState, useEffect } from 'react';
import { useAppData } from '../../AppContext';
import giftImg from '../../images/icons/gift.png';
import Back from '../Back';
import './friends.scss';
import { SendInvite, getFriendsList } from '../../http';
import create from './create.png';
import { useTranslation } from 'react-i18next';

export const InviteFriend = () => {
    const { t } = useTranslation();
    const { referredCount } = useAppData();
    const [show, setShow] = useState(false);
    const [friendList, setFriendList] = useState([]);

    useEffect(() => {
        const fetchFriends = async () => {
            try {
                const data = await getFriendsList();
                if (data && Array.isArray(data.friends)) {
                    setFriendList(data.friends);
                } else {
                    setFriendList([]);
                }
            } catch (error) {
                console.error('Failed to fetch friends:', error);
                setFriendList([]);
            }
        };

        fetchFriends();
    }, []);

    const handleFillClick = () => {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink("https://forms.gle/DW4rMNf8AMUUjMc8A");
        } else {
            window.open("https://forms.gle/DW4rMNf8AMUUjMc8A", '_blank');
        }
    };

    const handleClick = () => {
        SendInvite();
        window.Telegram.WebApp.close();
    };

    return (
        <div className='outer-container_boost'>
            {show ? (
                <div className='outer_container_coming'>
                    <span className="close" onClick={() => setShow(false)}>&times;</span>
                    <div className='container_glow_create'>
                        <img className="img_create_team" src={create} alt="Create team" />
                        <div className='glow_create_team'></div>
                        <div className='text_container_create'>
                            <div className='text_create'>{t('friends.createTeamPrompt.title')}</div>
                            <div className='subtext_create'>{t('friends.createTeamPrompt.subtitle')}</div>
                        </div>
                        <button className="button_create_create button-purple" onClick={handleFillClick}>
                            {t('friends.createTeamPrompt.button')}
                        </button>
                    </div>
                </div>
            ) : null}

            <div className='container_glow_friends'>
                <Back />
                <div className="tab-header">
                    <img src="https://nikicoin-bucket.s3.eu-north-1.amazonaws.com/images/boxfriends.png" draggable="false" />
                    {/* <h2 className="tab-title">{t('friends.title')}</h2> */}
                </div>

                <div className="tab_block_friends invite_btn_container">
                    <button className="button_create button-purple" onClick={() => setShow(true)}>
                        {t('friends.createTeam')}
                    </button>
                    <button className="button_invite button-purplr" onClick={handleClick}>
                        {t('friends.invite')}
                    </button>
                </div>
                <div className='coming_title' style={{ marginTop: "20px" }}>
                    {t('friends.friendsListTitle')}
                </div>
                {friendList.length > 0 ? (
                    <div className='friedns_column'>
                        {friendList.map((friend, index) => (
                            <div className='friedns_row' key={index}>
                                <img className='friend_img' src={friend.profile_photo} alt={friend.name} />
                                <div>{friend.name}</div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>{t('friends.noFriends')}</div>
                )}
            </div>
        </div>
    );
};
