import React, { useState, useEffect } from 'react';
import "./league.scss";
import { useAppData } from '../../AppContext';
import arrow from "./arrow.png";
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLeague } from '../../http';
import gem from "./gem.png"
function League() {
    const { t } = useTranslation();
    const { leagues, total_coins_earned, refferedCount } = useAppData();
    const { leagueName } = useParams();
    const navigate = useNavigate();
    const [selected, setSelected] = useState('day');
    const [currentLeagueIndex, setCurrentLeagueIndex] = useState(0);
    const [leagueData, setLeagueData] = useState([]);
    const leagueColors = [];

    useEffect(() => {
        const index = leagues.findIndex(league => league.name.replace(/\s/g, '') === leagueName);
        if (index !== -1) {
            setCurrentLeagueIndex(index);
        } else {
            navigate('/league/not-found');
        }
    }, [leagueName, leagues, navigate]);

    useEffect(() => {
        const fetchLeagueData = async () => {
            try {
                const leagueNameLower = leagues[currentLeagueIndex].name.toLowerCase();
                const data = await getLeague(selected, leagueNameLower);
                setLeagueData(data.league);
            } catch (error) {
                console.error('Error fetching league data:', error);
            }

        };
        if (leagues[currentLeagueIndex]) {
            fetchLeagueData();
        }
    }, [selected, currentLeagueIndex, leagues]);

    const nextLeague = () => {
        const nextIndex = currentLeagueIndex + 1 >= leagues.length ? 0 : currentLeagueIndex + 1;
        navigate(`/league/${leagues[nextIndex].name.replace(/\s/g, '')}`);
    };

    const prevLeague = () => {
        const prevIndex = currentLeagueIndex - 1 < 0 ? leagues.length - 1 : currentLeagueIndex - 1;
        navigate(`/league/${leagues[prevIndex].name.replace(/\s/g, '')}`);
    };

    const getProgressWidth = () => {
        if (leagues[currentLeagueIndex].name === t('league.friends')) {
            return `${(refferedCount / 3) * 100}%`;
        }
        return total_coins_earned > leagues[currentLeagueIndex].coin_limit
            ? "100%"
            : `${Math.min(100, (total_coins_earned / leagues[currentLeagueIndex].coin_limit) * 100)}%`;
    };

    function handleBack() {
        navigate("/");
    }

    useEffect(() => {
        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(handleBack);
        }

        return () => {
            if (window.Telegram?.WebApp) {
                window.Telegram.WebApp.BackButton.offClick(handleBack);
            }
        };
    }, [navigate]);

    return (
        <div className='league_container' style={{ backgroundColor: leagueColors[currentLeagueIndex % leagueColors.length] }}>
            <div className='league_back'>
                <div className="header_league" style={{ backgroundImage: `url(${leagues[currentLeagueIndex].logo})` }}>
                    <div className="header_league_background"></div>
                    <img onClick={prevLeague} src={arrow} className='arrow_left arrow' alt="Previous"></img>
                    <img onClick={nextLeague} className="arrow" src={arrow} alt="Next"></img>
                    <div className='coins_containers'>
                        <div className='league_name'>{leagues[currentLeagueIndex].name} {t('league.league')}</div>
                        <div className='coins_league'>
                            {leagues[currentLeagueIndex].name === t('league.friends')
                                ? t('league.friendsProgress', { count: refferedCount })
                                : t('league.coinProgress', {
                                    earned: total_coins_earned.toLocaleString('en-US'),
                                    limit: leagues[currentLeagueIndex].coin_limit.toLocaleString('en-US')
                                })}
                        </div>

                        <div className="progress_bar_league">
                            <div className="progress_league" style={{ width: getProgressWidth() }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='outer-container_league'>
                <div className='container_glow_league'>
                    <div className='range_selector'>
                        <div className={selected === "day" ? "range selected_league" : "range"} onClick={() => setSelected("day")}>{t('league.day')}</div>
                        <div className={selected === "week" ? "range selected_league " : "range"} onClick={() => setSelected("week")}>{t('league.week')}</div>
                    </div>
                    <div className='league_info'>
                        {leagueData != [] && leagueData.map((data, index) => (
                            <div key={index} className='league_info_item'>
                                <span>
                                    <img className='img_league' src={data.profile_photo}></img>
                                    <div className='league_info_name'>{data.name}</div>

                                </span>
                                <span>
                                    <div className='league_info_value'>{data.total_earned}</div>
                                    <img src={gem} className="img" alt="Coin-icon" />

                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default League;
