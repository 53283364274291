import React from 'react';
import niki from "./Niki.png"

const Meme = ({ name, logo, coins_per_hour, level, upgradeCost, onMemeClick, is_seen }) => {

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'K';
        }
        return num.toLocaleString('de-DE');
    };
    return (
        <div className="petCard" onClick={onMemeClick}>
            <div className="iconWrapper">
                <img src={logo} alt={name} className="petIcon" />
            </div>
            <div className="infoWrapper">
                <h2 className="petName">{name}</h2>
                <div className="profitValue flex_center"><img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_shop" alt="Coin-icon" /> +{coins_per_hour} /hr</div>
                <div className="levelInfo">
                    <span className="levelLabel">lvl {level}</span>
                    <span className="coins flex_center"> <img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_shop" alt="Coin-icon" />{formatNumber(upgradeCost)}</span>
                </div>
            </div>
        </div>
    );
};


export default Meme;