import React from 'react';
import "./tasks.scss";
import { openLink } from '../../Utils/telegramUtils';
function Promotion({ title, coins, onClick, logo, text, subtext, status, link, background, coming, doble }) {
    return (
        <div
            className={"promotionTab " + (doble ? " doble_promotion " : "") + (status === "CM" ? " completed" : "") + (coming ? " coming_soon" : "")}
            onClick={status === "CM" ? () => openLink(link) : onClick}
            style={doble ? { height: '230px' } : {}}

        >
            <div className='promotion_background' style={{ backgroundImage: `url(${background})`, ...(doble ? { opacity: '0.8' } : {}) }}></div>
            <div className='promotion_content'>
                <div className='promotion_title'>{title}</div>
                {/* <img className='promotion_img' src={logo} alt={`${title} logo`} /> */}
                {
                    !logo ? null : <img src={logo} className='promotion_img' />
                }
                <div>{text}</div>
                <div className='promotion_subtext'>{subtext}</div>

                {status === "CM" ? (
                    <svg width="20" height="20" viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.136364 19.4773L6.54545 13.0455L17.0682 23.3864L40.4091 0.136364L46.8636 6.56818L17.0682 36.2045L0.136364 19.4773Z" fill="white" />
                    </svg>

                ) : (
                    <div className='row_promotion'>
                        <div>+{coins.toLocaleString('en-US')}</div>
                        <img className='coin_img' src="https://nikicoin.store/media/images/coin.png" alt="coin" />
                    </div>
                )}
            </div>
        </div >
    );
}


export default Promotion;
