import React, { useState, useEffect } from 'react';
import "./stat.css";
import { useAppData } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StatBlock = () => {
    const { t } = useTranslation();
    const { passiveIncomePerHour, clickCost, total_coins_earned, dollar_coin } = useAppData();
    const navigate = useNavigate();
    const [leagueInfo, setLeagueInfo] = useState({ name: '', color: '' });

    const leagues = [
        { name: '🎉 Friend', color: '#C7C7C7', threshold: 0 },
        { name: '🥈 Silver', color: '#91B3F8', threshold: 300000 },
        { name: '🥇 Gold', color: '#F3CD46', threshold: 1000000 },
        { name: '💎 Diamond', color: '#37CDEE', threshold: 10000000 },
        { name: '⭐ Epic', color: '#C019CD', threshold: 50000000 },
        { name: '🏅 Legendary', color: '#3940E2', threshold: 100000000 },
        { name: '🏆 Master', color: '#E73232', threshold: 1000000000 },
    ];

    useEffect(() => {
        const sortedLeagues = [...leagues].sort((a, b) => a.threshold - b.threshold);
        const currentLeague = sortedLeagues.find(league => total_coins_earned < league.threshold);
        if (currentLeague) {
            setLeagueInfo({ name: currentLeague.name, color: currentLeague.color });
        } else {
            const highestLeague = sortedLeagues[sortedLeagues.length - 1];
            setLeagueInfo({ name: highestLeague.name, color: highestLeague.color });
        }
    }, [total_coins_earned]);

    const formatNumberLeague = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(0) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0) + 'K';
        }
        return num;
    };

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(0) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 4 }) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 3 }) + 'K';
        }
        return num;
    };

    const getNextLeagueThreshold = () => {
        const thresholds = [300000, 1000000, 10000000, 50000000, 100000000, 1000000000];
        for (let threshold of thresholds) {
            if (total_coins_earned < threshold) {
                return threshold;
            }
        }
        return "Max";
    };

    return (
        <div className="stat-block">
            <div className="stat-item" onClick={() => navigate("/boosts")}>
                <div className='stat_text' style={{ color: "#FF8A00" }}>{t('statBlock.profitPerClick')}</div>
                <div>
                    <span>+{clickCost}</span>
                    <img src={dollar_coin} alt={t('statBlock.profitPerClick')} />
                </div>
            </div>
            <div className="stat-item" onClick={() => navigate(`/league/${leagueInfo.name.replace(/[^a-zA-Z0-9]/g, '')}`)}>
                <div className='stat_text' style={{ color: "#8000FF" }}>{t('statBlock.nextLeague')}</div>
                <div>
                    <span style={{ fontSize: "15px" }}>{`${formatNumberLeague(total_coins_earned)}/${formatNumberLeague(getNextLeagueThreshold())}`}</span>
                    <img src={dollar_coin} alt={t('statBlock.nextLeague')} />
                </div>
            </div>
            <div className="stat-item" onClick={() => navigate("/earn/income")}>
                <div className='stat_text' style={{ color: "#0094FF" }}>{t('statBlock.profitPerHour')}</div>
                <div>
                    <span>{passiveIncomePerHour == null ? 0 : formatNumber(passiveIncomePerHour)}</span>
                    <img src={dollar_coin} alt={t('statBlock.profitPerHour')} />
                </div>
            </div>
        </div>
    );
};

export default StatBlock;
