import React from 'react';
import './NotEnoughMoney.scss';
import img from './img1.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
function NotEnoughMoney({ onClick }) {
    const { t } = useTranslation();
    const navigate = useNavigate();



    return (
        <div className='outer_container_NotEnoughMoney'>

            <div className='container_glow_NotEnoughMoney'>
                <div className='text_coming_NotEnoughMoney'>Not enough money</div>
                <img src={img} className='img_coming_NotEnoughMoney' alt="coming soon" />
                <div className='glow_coming'></div>
                <div className='buttom_NotEnoughMoney' onClick={onClick ? onClick : () => navigate("/")}>MAIN MENU</div>
            </div>



        </div>
    );
}

export default NotEnoughMoney;
