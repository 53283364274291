import { TaskOpened } from "../LockedTask/Icons";
import "./OpenedTask.scss";

export const OpenedTask = ({ lvl, stars = 3, className }) => {
  return (
    <div className={`opened-wrapper ${className} `}>
      <TaskOpened />
      <ul className="stars-list">
        {Array.from({ length: stars }).map((_, i) => {
          return (
            <li key={i} className={"star-icon"}>
              ⭐️
            </li>
          );
        })}
      </ul>
    </div>
  );
};
