import React, { useState } from 'react';
import './settings.scss';
import lenguague from './lenguague.png';
import wallet from './wallet.png';
import { useTranslation } from 'react-i18next';
import Back from '../Back';
import { useNavigate } from 'react-router-dom';
import ComingSoon from '../ComingSoon/ComingSoon';
import gem from "./gem.png"
import coin from "./coin.png"
import chest from "./chest.png"
import coingem from "./coinGrm.png"
import { claimDailyRewords } from "../../http"
import { useAppData } from "../../AppContext"
function Settings() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [selectedDaily, setSelectedDaily] = useState(null);
    // const { dailys, dollar_coin } = useAppData()

    const dailys = [
        {
            "day": 1,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Claimed"
        },
        {
            "day": 2,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Available"
        },
        {
            "day": 3,
            "coins_reward": 3250000,
            "diamonds_reward": 25,
            "status": "Noavailable"
        },
        {
            "day": 4,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Noavailable"
        },
        {
            "day": 5,
            "coins_reward": 3250000,
            "diamonds_reward": 50,
            "status": "Noavailable"
        },
        {
            "day": 6,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Noavailable"
        },
        {
            "day": 7,
            "coins_reward": 3250000,
            "diamonds_reward": 75,
            "status": "Noavailable"
        },
        {
            "day": 8,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Noavailable"
        },
        {
            "day": 9,
            "coins_reward": 3250000,
            "diamonds_reward": 100,
            "status": "Noavailable"
        },
        {
            "day": 10,
            "coins_reward": 3250000,
            "diamonds_reward": 150,
            "status": "Noavailable"
        },
        {
            "day": 11,
            "coins_reward": 3250000,
            "diamonds_reward": 0,
            "status": "Noavailable"
        },
        {
            "day": 12,
            "coins_reward": 3250000,
            "diamonds_reward": 300,
            "status": "Noavailable"
        }
    ]

    const areAllRewardsUnavailable = dailys.every(dayReward => dayReward.status !== 'Available');

    const handleClaimReword = async () => {
        try {
            await claimDailyRewords();
            // const checkedValue = result.checked;
            // if (status) {
            //     if(checkedValue) {
            //         await completeTask(id);
            //         handleEditCoins(task__coins_reward);
            //     } 
            // } else {
            //     seeTask(id);
            // }
            handleCloseModal();
        } catch (error) {
            console.error("Failed to clain reword", error);
        }
    }
    const handleCloseModal = () => {
        setSelectedDaily(null);
    };
    function interpolateColor(color1, color2, ratio) {
        const hexToRgb = hex => {
            let r = parseInt(hex.slice(0, 2), 16);
            let g = parseInt(hex.slice(2, 4), 16);
            let b = parseInt(hex.slice(4, 6), 16);
            return { r, g, b };
        };

        const rgbToHex = ({ r, g, b }) =>
            '#' + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');

        const start = hexToRgb(color1);
        const end = hexToRgb(color2);

        const r = Math.round(start.r + (end.r - start.r) * ratio);
        const g = Math.round(start.g + (end.g - start.g) * ratio);
        const b = Math.round(start.b + (end.b - start.b) * ratio);

        return rgbToHex({ r, g, b });
    }

    function getGemColor(gems) {
        if (gems > 0 && gems <= 25) {
            return "#009DFF";
        } else if (gems <= 50) {
            return interpolateColor("1866FF", "062766", (gems - 25) / 25);
        } else if (gems <= 75) {
            return interpolateColor("BD00FF", "062766", (gems - 50) / 25);
        } else if (gems <= 100) {
            return interpolateColor("FF8A00", "412B00", (gems - 75) / 25);
        } else if (gems <= 150) {
            return interpolateColor("61FF00", "215800", (gems - 100) / 50);
        } else if (gems <= 300) {
            return interpolateColor("00FF85", "03462A", (gems - 150) / 150);
        }
    }

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(0) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 3 }) + 'K';
        }
        return num;
    };
    return (
        <div>
            {show ? <ComingSoon onClick={() => setShow(false)} /> : ""}
            <Back />
            <div className='settings_text'>{t('settings')}</div>
            <div className='outer-container_settings'>
                <div className='container_glow_settings'>
                    <div className="settings-container">
                        <div className="settings-block">
                            <div className="settings-item" onClick={() => navigate("/lenguague_picker")}>
                                <div className="settings-text">
                                    <div className="settings-title">{t('changeLanguage')}</div>
                                    <div className="settings-subtitle">{i18n.language}</div>
                                </div>
                                <img className="settings-icon" src={lenguague} alt="language icon" />
                            </div>

                            <div className="settings-item" onClick={() => setShow(true)}>
                                <div className="settings-text">
                                    <div className="settings-title">{t('connectWallet')}</div>
                                    <div className="settings-subtitle">{t('none')}</div>
                                </div>
                                <img className="settings-icon" src={wallet} alt="wallet icon" />
                            </div>

                            {/* <div className="gradient-border settings-item" onClick={() => setSelectedDaily(true)}> */}
                            <div className="gradient-border settings-item" onClick={() => setShow(true)}>
                                <div className="settings-text">
                                    <div className="settings-title">Daily Charity REWARD</div>
                                    <div className="settings-subtitle">
                                        <div className='bonus'>+ 10M <img src={coin} /></div>
                                        <div className='bonus'>+ 700 <img src={gem} /></div>
                                    </div>
                                </div>
                                <img className="chest_img" src={chest} alt="wallet icon" />
                                <div className='glow_chest'></div>
                            </div>

                            {selectedDaily && (
                                <div className="modal">
                                    <div className="modal-container-tasks">
                                        <span className="close" onClick={handleCloseModal}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="10" fill="#CACACA" fill-opacity="0.46" />
                                                <path d="M16 8L8 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8 8L16 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                        <div className="modal-content_tasks dailyReword">
                                            <img className='calender_icon' src={chest} alt="Calendar" />
                                            <div className="dailyInfo">
                                                <h2>Daily Task</h2>
                                                <p className='dailyText'>Accrue coins for logging into the game daily without skipping</p>
                                            </div>
                                            <div className="rewords">
                                                {dailys.map(dayReward => (
                                                    <div className={`reword ${dayReward.status}`} key={dayReward.day} style={{ background: getGemColor(dayReward.diamonds_reward) }}>
                                                        <h3>Day {dayReward.day}</h3>
                                                        <img src={dayReward.diamonds_reward > 1 ? coingem : coin} alt="coin" />
                                                        <div className='rewardCon'>
                                                            <p className='rewordValue'>+ {formatNumber(dayReward.coins_reward)}</p>
                                                            {dayReward.diamonds_reward > 1 ? <p className='rewordValue'>+ {formatNumber(dayReward.diamonds_reward)} <img className='gemImg' src={gem} /> </p> : null}
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                            <button
                                                className={`open_task button_tasks ${areAllRewardsUnavailable ? 'disabled' : ''}`}
                                                onClick={areAllRewardsUnavailable ? handleCloseModal : handleClaimReword}
                                            >
                                                {areAllRewardsUnavailable ? 'Come back tomorrow' : 'Watch and Collect'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Settings;
