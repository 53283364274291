import React from 'react';
import "./oops.scss";
import main from "./main.png"
import oops from "./opps.png"
import reload from "./Loading-@ui8_bot 1.png"

function Oops() {

    const Close = () => {
        window.Telegram.WebApp.close()
    }


    return (
        <div className="mobile-prompt-container">
            <div className='outer_container_play'>
                <div className='container_glow_play'>
                    {/* <div className="mobile_prompt_text">
                    </div> */}
                    <div className='oops_container'>
                        <div>OOPS</div>
                        <img src={oops}></img>
                    </div>
                    <div className='text_main'>
                        Something went wrong
                        <div className='gradient_oops'>Please reload the game</div>
                    </div>
                    <button className='btn_reload' onClick={() => Close()} >reload <img src={reload}></img></button>
                    <img className='img_computer' src={main}></img>
                </div>
            </div>
        </div>

    );
}

export default Oops;
