import React from 'react';
import { useAppData } from '../../AppContext';
import "./tasks.scss"
const PetCard = ({ name, logo, coins_per_hour, level, upgradeCost, additional_friends, type, onMemeClick, is_seen, next_coins_per_hour }) => {

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + 'K';
        }
        return num.toLocaleString('de-DE');
    };
    const isSubscribeLocked = async (type, is_seen) => {
        console.log(type === "subscribe" && is_seen)
        if (type === "subscribe") {
            return is_seen;
        }
        return true;
    };

    const isLocked = additional_friends > 0 && is_seen;
    return (
        <div className={`MemeTaksCard`} onClick={onMemeClick}>
            <div className={`${isLocked || !isSubscribeLocked ? 'lockedCard' : ''}`}></div>
            <div className="iconWrapperMeme">
                <img src={logo} alt={name} className="petIcon" />
            </div>
            <div className="infoWrapperMemeTask">
                <h2 className="memeName">{name}</h2>
                {isLocked && <div className="additionalFriends">{`🔐 Invite ${additional_friends} more friends`}</div>}
                {!isSubscribeLocked && type !== "friends" && <div className="additionalFriends">🔐 Subscribe channel</div>}
                <div className="levelInfo">


                    <div className='task_meme_flex'>
                        <div className='income_taksmeme'>Прибыль в час:</div>

                        {!isLocked && isSubscribeLocked ?

                            <span className="levelLabel">lvl {level}</span>
                            :
                            <div className="profitValue flex_center">
                                <img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_shop" alt="Coin-icon" /> +{next_coins_per_hour}
                            </div>
                        }

                    </div>
                    {!isLocked && isSubscribeLocked &&

                        <span className="coins flex_center task_meme_flex">
                            <div className="profitValue flex_center">
                                <img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_shop" alt="Coin-icon" /> +{coins_per_hour} /hr
                            </div>
                            <div className='price_taskmeme'>

                                <img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_shop" alt="Coin-icon" />
                                {formatNumber(upgradeCost)}
                            </div>

                        </span>
                    }
                </div>

            </div>
        </div>
    );
};

export default PetCard;
